import { useMutation } from "@apollo/client";
import {
  INSTRUCTION_ATTACHMENT_UPDATE,
  INSTRUCTION_ATTACHMENT_CREATE,
} from "graphql/mutation/instruction-gql";
import { GET_INSTRUCTION_ATTACHMENTS } from "graphql/query/instruction-gql";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationInstructionAttachmentCreateUpdate = ({
  attachmentID,
  variables,
}) => {
  const INSTRUCTION_ATTCHMENT_CREATE_UPDATE = attachmentID
    ? INSTRUCTION_ATTACHMENT_UPDATE
    : INSTRUCTION_ATTACHMENT_CREATE;
  const attachmentCreateUpdate = attachmentID
    ? "instructionAttachmentUpdate"
    : "instructionAttachmentCreate";

  const shouldRefetchQueries =
    INSTRUCTION_ATTCHMENT_CREATE_UPDATE === INSTRUCTION_ATTACHMENT_UPDATE;

  const [_setAttachmentCreateUpdate, { loading, data }] = useMutation(
    INSTRUCTION_ATTCHMENT_CREATE_UPDATE,
    {
      update(cache, { data }) {
        const {
          [attachmentCreateUpdate]: { label, message },
        } = data;

        if (attachmentID) {
          successNotification({
            title: label,
            description: message,
          });
        }
      },

      onError(error) {
        errorNotification(error);
      },

      refetchQueries: shouldRefetchQueries
        ? [
            {
              query: GET_INSTRUCTION_ATTACHMENTS,
              variables,
            },
          ]
        : [],
    }
  );

  return {
    _setAttachmentCreateUpdate,
    loadingMutationAttachmentCreateUpdate: loading,
    dataMutationAttachmentCreateUpdate: data,
  };
};

export default useMutationInstructionAttachmentCreateUpdate;
