import React, { useState, useEffect } from "react";
import { Button, Form } from 'antd';

import { gqlBuilderWhere, helperFunc } from "utils";
import { DrawerStandart, Localize }    from "components/service";
import CloudinaryReportForms           from "../forms";
import Icons from 'components/icons';


const CloudinaryReportDrawerFilterMain = ( { 
    filterInit = {},
    setFilters = () => { },
    setResetSorting = () => { },
    modelsFilters,
    filters,
    setSearchText,
    modelFilters   = 'cloudinaryReportsFilters',
    modelSorting   = 'cloudinaryReportsSorting',
    resetFields    = ['DATE_AT'],
    formFieldsHide = [],
    objOrderBy 
} ) => {

    const [ form ]                      = Form.useForm();
    const [ filterList, setFilterList ] = useState( null );
    const [ count, setCount ]           = useState();

    let filterCount = helperFunc.countFilterList(count);

    /*eslint-disable */
    useEffect(() => {

        if( filters ){

            localStorage.setItem( modelFilters, JSON.stringify( { ...filterInit, ...modelsFilters, ...filterList } ) );

        } else { 

            form.resetFields(resetFields); 

        }


    }, [ filters ]);


    useEffect(() => {

        if( modelsFilters ){
            setFilters( gqlBuilderWhere( modelsFilters ) );
            setCount( modelsFilters );
        }

    }, []);
    /*eslint-enable */

    return (
        <>
            { setFilters &&
                <Form
                    key       = "filters-form"
                    className = "clear-css-prop table-filter-form"
                    layout    = "vertical"
                    form      = { form }
                >
                    <DrawerStandart
                        width       = "287"
                        extraClass  = { 'modal-form filters' }
                        modalButton = {
                            <Button>
                                <Icons.Filter />
                                <span><Localize>FORMS.Button_Text_ApplyFilters</Localize></span>
                                { filterCount && <span className="filter-count">{ filterCount }</span> }
                            </Button>
                        }
                    >
                        <strong className="modal-title">
                            <Localize>FORMS.Title_Text_Filters</Localize>
                        </strong>

                        <CloudinaryReportForms.FilterMain
                            filterInit      = { filterInit }
                            setCount        = { setCount }
                            setFilters      = { setFilters }
                            setFilterList   = { setFilterList }
                            filterList      = { filterList }
                            gqlBuilderWhere = { gqlBuilderWhere }
                            modelsFilters   = { modelsFilters }
                            modelFilters    = { modelFilters }
                            formFieldsHide  = { formFieldsHide }
                        />

                    </DrawerStandart>

                    <Button
                        type    = "link"
                        onClick = { () => {
                            setFilterList( {} );
                            setCount( {} );
                            setFilters( gqlBuilderWhere( {} ) );
                            setResetSorting( objOrderBy );
                            localStorage.removeItem( modelFilters );
                            localStorage.removeItem( modelSorting );
                        } }
                    >
                        <span><Localize>FORMS.Button_Text_ResetFilters</Localize></span>
                    </Button>
                </Form>
            }
        </>
    );

};

export default CloudinaryReportDrawerFilterMain;