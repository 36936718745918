import React, { useState, useEffect } from "react";
import { Input, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import InstructionForms from "../../forms";
import Icons from "components/icons";
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;

const InstructionTableFilter = ({
  filterInit = {},
  setFilters = () => {},
  setResetSorting = () => {},
  postsFilters,
  filters,
  setSearchText,
  modelFilters = "postsFilters",
  modelSorting = "postsSorting",
  resetFields = ["AUTHOR_ID", "STATUS", "CREATED_AT"],
  formFieldsHide = [],
  objOrderBy,
}) => {
  const [form] = Form.useForm();

  const [filterList, setFilterList] = useState(null);
  const [count, setCount] = useState();

  let filterCount = helperFunc.countFilterList(count);

  /*eslint-disable */
  useEffect(() => {
    if (filters) {
      localStorage.setItem(
        modelFilters,
        JSON.stringify({ ...filterInit, ...postsFilters, ...filterList })
      );
    } else form.resetFields(resetFields);
  }, [filters]);

  useEffect(() => {
    if (postsFilters) {
      setFilters(gqlBuilderWhere(postsFilters));
      setCount(postsFilters);
    }
  }, []);
  /*eslint-enable */

  return (
    <>
      <Search
        className="filter-search"
        prefix={<SearchOutlined />}
        placeholder={
          Localize({ children: "SEARCH.Input_Placeholder_Table_Title" }).props
            .children
        }
        onChange={(e) => setSearchText(e.target.value)}
      />

      {setFilters && (
        <Form
          key="filters-form"
          className="clear-css-prop table-filter-form"
          layout="vertical"
          form={form}
        >
          <DrawerStandart
            width="287"
            extraClass={"modal-form filters"}
            modalButton={
              <Button>
                <Icons.Filter />
                <span>
                  <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </span>
                {filterCount && (
                  <span className="filter-count">{filterCount}</span>
                )}
              </Button>
            }
          >
            <strong className="modal-title">
              <Localize>FORMS.Title_Text_Filters</Localize>
            </strong>

            <InstructionForms.Filter
              filterInit={filterInit}
              setCount={setCount}
              setFilters={setFilters}
              setFilterList={setFilterList}
              filterList={filterList}
              gqlBuilderWhere={gqlBuilderWhere}
              postsFilters={postsFilters}
              modelFilters={modelFilters}
              formFieldsHide={formFieldsHide}
              form={form}
            />
          </DrawerStandart>

          <Button
            type="link"
            onClick={() => {
              setFilterList({});
              setCount({});
              setFilters(gqlBuilderWhere({}));
              setResetSorting(objOrderBy);
              localStorage.removeItem(modelFilters);
              localStorage.removeItem(modelSorting);
            }}
          >
            <span>
              <Localize>FORMS.Button_Text_ResetFilters</Localize>
            </span>
          </Button>
        </Form>
      )}
    </>
  );
};

export default InstructionTableFilter;
