import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";

import { GET_INSTRUCTION_ATTACHMENTS } from "graphql/query/instruction-gql";

import Instruction from "components/instruction";
import Icons from "components/icons";
import { Tables } from "components/layout";
import { ModalStandard, Localize, UploadFile } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";

const basePath = Instruction.Const.basePath;
const tableHelper = Instruction.Helpers.TableAttachments;

const InstructionAttachmentsSubPage = ({ instruction }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Instructions" }).props
        .children,
      path: `${basePath}`,
    },
    {
      label: instruction?.title ?? (
        <strong>the instruction has no title</strong>
      ),
      path: `${basePath}/${instruction?.id}`,
    },

    {
      label: Localize({ children: "BREADCRUMBS.Label_Attachments" }).props
        .children,
      path: `${basePath}/${instruction?.id}/attachments`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const [variables, setVariables] = useState({});
  const [dataModel, setDataModel] = useState({});

  const {
    _setAttachmentCreateUpdate,
    loadingMutationAttachmentCreateUpdate,
    dataMutationAttachmentCreateUpdate,
  } = Instruction.Hooks.attachmentCreateUpdate({ variables });

  const { _setAttachmentOrderSet } = Instruction.Hooks.attachmentOrderSet({
    variables,
  });

  let match = useRouteMatch(
    `${basePath}/${instruction.id}/attachments/page/:pageNum`
  );

  const attachment =
    dataMutationAttachmentCreateUpdate?.instructionAttachmentCreate?.instruction
      ?.relInstructionAttachments;

  const filteredAttachments = attachment?.filter(
    (att) => !dataModel?.data?.some((dm) => dm.id === att.id)
  )[0];

  return (
    <Tables.DragSorting
      model="instructionAttachments"
      extraClass="instruction-attachments"
      query={GET_INSTRUCTION_ATTACHMENTS}
      varSet={{
        instruction_id: instruction?.id,
        // perPage: 30,
      }}
      currentObj={instruction}
      routeUrl={`${basePath}/${instruction.id}/attachments`}
      currentPage={match ? parseInt(match.params.pageNum) : 1}
      setVariables={setVariables}
      setDataModel={setDataModel}
      mutationOrderSet={_setAttachmentOrderSet}
      objFilter={{}}
      objOrderBy={[ { column: "ORDER", order: "ASC" } ]}
      tableHelper={tableHelper}
    >
      <div className="upload-document-holder">
        <UploadFile
          accept="*/*"
          varSetMutation={{
            input: {
              instruction_id: instruction?.id,
            },
          }}
          setMutation={_setAttachmentCreateUpdate}
          loading={loadingMutationAttachmentCreateUpdate}
          uploadBtn={
            <div className="upload-document-box">
              <Icons.Download />{" "}
              <strong className="text">
                <Localize>GLOBAL.Button_Text_AddFile</Localize>
              </strong>
            </div>
          }
        />
      </div>

      {attachment && (
        <ModalStandard
          maskClosable={false}
          defaultShow={true}
          closable={false}
          width={380}
          extraClass={"modal-form"}
        >
          <strong className="modal-title">
            <Localize>FORMS.Title_AddFile</Localize>
          </strong>
          <Instruction.Forms.Attachment
            documentCreate={true}
            attachment={filteredAttachments}
            variables={variables}
          />
        </ModalStandard>
      )}
    </Tables.DragSorting>
  );
};

export default InstructionAttachmentsSubPage;
