import { Link } from "react-router-dom";
import { Avatar } from "antd";
import Post from "components/post";
import User from "components/user";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const postBasePath = Post.Const.basePath;


const PostOverviewSubPage = ({ post, history }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Posts" }).props.children,
            path: `${ postBasePath }`
        },
        {
            label: post?.title ?? <strong>the post has no title</strong>,
            path: `${ postBasePath }/${ post?.id }`
        },
    ]


    useBreadCrumbs(breadcrumbs);


    return (
        <div className="row-grid col-lg-2">

            <div className="col-left">
                <Link to={ `${ User.Const.basePath }/${ post?.author.id }` } className="d-flex align-items-center">
                    <div style={ { minWidth: '75px' } }>
                        <Avatar style={ { minWidth: '60px', minHeight: '60px' } } size="large" icon={ !!post?.author?.avatar ? <img src={ `${ post.author.avatar }` } alt='avatar' /> : post.author.initials } />
                    </div>
                    <div>
                        <strong>{ post?.author?.nickname ?? <strong>&mdash;</strong> }</strong> <br />
                        <span>{ post?.author?.name ?? <strong>&mdash;</strong> }</span>
                    </div>
                </Link>
                <br />
                <strong>Post created: { post?.created_at }</strong>
                <br />
                <br />

                <Post.Forms.Post post={ post } history={ history } />
            </div>

            <div className="col-right"></div>
        </div>
    )
}

export default PostOverviewSubPage;