import { Link } from "react-router-dom";
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Avatar, } from 'antd';
import { useLogout, useMe } from "components/use-hooks";
import { Localize } from "components/service";


import './dropdown-menu.scss';


const DropMenu = () => {

  const { me } = useMe();
  const { logout } = useLogout();

  const avatar = <Avatar size="small" icon={ !!me?.avatar ? <img src={ `${ me.avatar }` } alt='avatar' /> : me?.initials } />

  const items = [
    {
      key: 'profile',
      label: <Link to={ { pathname: `/users/${ me.id }` } } > <UserOutlined /> &nbsp; <Localize>MENUS_DROP.Label_MyProfile</Localize></Link>,
    },
    {
      key: 'logout',
      label: <span onClick={ () => logout({ redirectRoute: "/" }) }><LogoutOutlined /> &nbsp; <Localize>MENUS_DROP.Label_Logout</Localize></span>
    }
  ];


  return (
    <Dropdown
      className="dropdown-profile"
      menu={ { items } }
      trigger={ [ 'click' ] } >

      <span className="my-profile-link" onClick={ e => e.preventDefault() }>
        { avatar } &nbsp; &nbsp; <span className="profile-info"> { me.name } </span> <DownOutlined />
      </span>
    </Dropdown>
  )
};


export default DropMenu;


