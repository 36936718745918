import InstructionFiltersForm from "./instruction-filters-form";
import InstructionEditForm from "./instruction-edit-form";
import InstructionAttachmentForm from "./instruction-attachment-form";
import "./forms.scss";

const InstructionForms = {
  Instruction: InstructionEditForm,
  Attachment: InstructionAttachmentForm,
  Filter: InstructionFiltersForm,
};

export default InstructionForms;
