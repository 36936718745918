import { useMutation } from "@apollo/client";

import { INSTRUCTION_ATTACHMENT_DELETE } from "graphql/mutation/instruction-gql";

import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationInstructionAttachmentDelete = (id, documentCreate) => {
  const [_setMutationInstructionAttachmentDelete, { loading }] = useMutation(
    INSTRUCTION_ATTACHMENT_DELETE,
    {
      update(cache, { data }) {
        const {
          instructionAttachmentDelete: { label, message },
        } = data;

        cache.evict({
          id: cache.identify({ id, __typename: "InstructionAttachment" }),
        });
        cache.gc();

        if (!documentCreate) {
          successNotification({
            title: label.toUpperCase(),
            description: message,
          });
        }
      },

      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationInstructionAttachmentDelete,
    loadingMutationInstructionAttachmentDelete: loading,
  };
};

export default useMutationInstructionAttachmentDelete;
