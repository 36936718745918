import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  INSTRUCTION_UPDATE,
  INSTRUCTION_CREATE,
} from "graphql/mutation/instruction-gql";
import InstructionConst from "../instruction-const";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationInstructionCreateUpdate = (id, redirect = true) => {
  const history = useHistory();

  const INSTRUCTION_CREATE_UPDATE = id ? INSTRUCTION_UPDATE : INSTRUCTION_CREATE;
  const instructionCreateUpdate = id
    ? "instructionUpdate"
    : "instructionCreate";

  const [_setInstructionCreateUpdate, { loading }] = useMutation(
    INSTRUCTION_CREATE_UPDATE,
    {
      update(cache, { data }) {
        const {
          [instructionCreateUpdate]: { label, message },
        } = data;

        redirect && history.push(InstructionConst.basePath);

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setInstructionCreateUpdate,
    loadingMutationInstructionCreateUpdate: loading,
  };
};

export default useMutationInstructionCreateUpdate;
