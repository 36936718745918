import { useMutation } from "@apollo/client";
import { INSTRUCTION_ATTCHMENT_ORDER_SET } from "graphql/mutation/instruction-gql";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationInstructionAttachmentOrderSet = () => {
  const [_setAttachmentOrderSet, { loading }] = useMutation(
    INSTRUCTION_ATTCHMENT_ORDER_SET,
    {
      update(cache, { data }) {
        const {
          instructionAttachmentOrderSet: { label, message },
        } = data;

        successNotification({
          title: label,
          description: message,
        });
      },

      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setAttachmentOrderSet,
    loadingMutationAttachmentOrderSet: loading,
  };
};

export default useMutationInstructionAttachmentOrderSet;
