import React from "react";
import { Link } from "react-router-dom";
import Instruction from "components/instruction";

const UserInstructionAttachmentViewsField = ({ user }) => {
  const instructionAttachmentViews = user?.relInstructionAttachmentsViews || [];

  return instructionAttachmentViews.length ? (
    instructionAttachmentViews.map(({ id, relInstructionAttachment }) => (
      <Link
        key={+id}
        to={`${Instruction.Const.basePath}/${relInstructionAttachment.instruction_id}`}
        className="tag-main tag-link ellipsis"
      >
        {relInstructionAttachment.title}
      </Link>
    ))
  ) : (
    <strong>&mdash;</strong>
  );
};

export default UserInstructionAttachmentViewsField;
