import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Table, Empty } from "antd";
import { useQuery } from "@apollo/client";
import SkeletonCustom from "components/skeleton";
import { useDebounce } from "components/use-hooks";
import { TotalItems } from "components/layout";
import { Localize } from "components/service";

const SkeletonData = SkeletonCustom.TableData;

const TableMain = ({
  querySkip = false,
  model,
  query,
  currentObj,
  routeUrl,
  currentPage,
  searchText,
  objFilter,
  tableHelper,
  children,
  varSet,
  extraClass = "",
  tableVisible = true,
  sortingName = false,
  resetSorting = false,
  entityRemoveUser = false,
  setVariables = () => {},
  setDataModel = () => {},
  objOrderBy = [{ column: "ID", order: "DESC" }],
  tableLayout = "fixed",
  actionFieldsHide = [],
  totalCounter = false,
  marginY = "my-2",
}) => {
  const history = useHistory();
  const perPage = varSet?.perPage ?? 10;
  const [orderBy, setOrderBy] = useState(objOrderBy);

  const variables = {
    text: useDebounce(searchText),
    first: perPage,
    page: currentPage,
    where: objFilter,
    orderBy,
    ...(varSet ?? undefined),
  };

  let { data, loading } = useQuery(query, {
    skip: querySkip,
    variables: { ...variables },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  model = data?.[model] ?? {};
  const { paginatorInfo } = model;

  /*eslint-disable */
  useEffect(() => {
    setVariables(variables);
    setDataModel(model);
  }, [data]);

  useEffect(() => {
    if (resetSorting) setOrderBy(resetSorting);
  }, [resetSorting]);
  /*eslint-enable */

  useEffect(() => {
    if (searchText !== "" && searchText !== undefined) {
      history.push(routeUrl);
    }
  }, [searchText, history, routeUrl]);

  const dataSource = loading
    ? SkeletonData(tableHelper.columns, perPage, marginY)
    : tableHelper.data({
        model: model.data,
        currentObj,
        variables,
        actionFieldsHide,
        entityRemoveUser,
      });

  return (
    <>
      {children}

      {totalCounter === true && (
        <TotalItems paginatorInfo={paginatorInfo} extraclass="mt-15 mb-15" />
      )}

      {tableVisible && (
        <Table
          className={`table-main ${extraClass}`}
          locale={{
            emptyText: (
              <div className="no-data-box">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                <span className="no-data-text">
                  <Localize>GLOBAL.Text_NoData</Localize>
                </span>
              </div>
            ),
            triggerDesc: (
              <Localize>TABLES.Column_Sorting_Tooltip_Descending</Localize>
            ),
            triggerAsc: (
              <Localize>TABLES.Column_Sorting_Tooltip_Ascending</Localize>
            ),
            cancelSort: (
              <Localize>TABLES.Column_Sorting_Tooltip_Cancel</Localize>
            ),
          }}
          dataSource={dataSource}
          columns={tableHelper.columns}
          tableLayout={tableLayout}
          size="middle"
          pagination={{
            // pageSizeOptions: [ "10", "20" ],
            showSizeChanger: false,
            position: ["bottomCenter"],
            pageSize: perPage,
            total: paginatorInfo?.total,
            current: currentPage,
            hideOnSinglePage: !(
              paginatorInfo?.total / paginatorInfo?.perPage >
              1
            ),
            onChange: (page) => {
              let path =
                page === 1 ? `${routeUrl}` : `${routeUrl}/page/${page}`;
              history.push(path);
            },
          }}
          onChange={(pagination, filters, { column, order }) => {
            if (column && order) {
              const orderBy = [
                {
                  column: column.columnIndex,
                  order: order === "ascend" ? "ASC" : "DESC",
                },
              ];

              setOrderBy(orderBy);
              sortingName &&
                localStorage.setItem(sortingName, JSON.stringify(orderBy));
            }
          }}
        />
      )}
    </>
  );
};

export default TableMain;
