import { Button } from 'antd';
import { userUseMutation } from '../../hooks';
import { errorNotification } from 'components/request-result';
import { Localize } from "components/service";


const UserSendPassButton = ({ userID, action }) => {
    const { _setMutationUserSendPass } = userUseMutation.sendPass();

    return (
        <>
            <p><Localize br={ <br /> } >GLOBAL.Modal_Text_UserSendPass</Localize></p>

            <br />
            <Button
                type="primary"
                onClick={ () => {
                    _setMutationUserSendPass({
                        variables: {
                            id: userID
                        }
                    }).catch((error) => {
                        errorNotification(error)
                    });
                    action();
                } }>

                <Localize>GLOBAL.Button_Text_Send</Localize>
            </Button>
        </>
    );
}



export default UserSendPassButton;