import { Localize } from "components/service";

const groupBasePath = '/cloudinary-reports';

const CLOUDINARYREPORT_TYPE_GENERAL = 'general';
const CLOUDINARYREPORT_TYPE_DAILY   = 'daily';

const cloudinaryReportType = [
    { text: <Localize>General</Localize>, value: CLOUDINARYREPORT_TYPE_GENERAL },
    { text: <Localize>Daily</Localize>, value: CLOUDINARYREPORT_TYPE_DAILY }
];

const CloudinaryReportConst = {
    basePath    : groupBasePath,
    type        : cloudinaryReportType,
    typeGeneral : CLOUDINARYREPORT_TYPE_GENERAL,
    typeDaily   : CLOUDINARYREPORT_TYPE_DAILY
};

export default CloudinaryReportConst;