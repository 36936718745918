import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/user/sub-pages';


const UserRoute = ({ user, history }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/profile` } >
                <SubPage.JobProfile
                    user={ user }
                    history={ history } />
            </Route>

            <Route path={ `${ path }/posts` } >
                <SubPage.Posts
                    user={ user }
                    history={ history } />
            </Route>

            <Route path={ `${ path }/posts/page/:pageNum` } exact>
                <SubPage.Posts
                    user={ user }
                    history={ history } />
            </Route>

            <Route path={ `${ path }` }>
                <SubPage.OverView
                    user={ user }
                    history={ history } />
            </Route>
        </Switch>
    );
};

export default UserRoute;