import UserOverviewSubPage from "./user-owerview-sub-page";
import UserJobProfileSubPage from "./user-job-profile-sub-page";
import UserPostSubPage from "./user-posts-sub-page";


const SubPage = {
    OverView: UserOverviewSubPage,
    JobProfile: UserJobProfileSubPage,
    Posts: UserPostSubPage
};

export default SubPage;
