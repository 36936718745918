import { Localize } from "components/service";


const cloudinaryReportsColumns = [
    {
        title       : <Localize>Date</Localize>,
        dataIndex   : 'date_at',
        columnIndex : 'DATE_AT',
        width       : '10%',
        sorter      : true,
        align       : "center"
    },
    {
        title       : <Localize>credits usage %</Localize>,
        dataIndex   : 'credits_usage',
        columnIndex : 'CREDITS_USAGE',
        width       : '10%',
        sorter      : true,
        align       : "center"
    },
    {
        title       : <Localize>requests</Localize>,
        dataIndex   : 'requests',
        columnIndex : 'REQUESTS',
        width       : '10%',
        sorter      : true,
        align       : "center"
    },
    {
        title       : <Localize>Transformations</Localize>,
        dataIndex   : 'transformations_usage',
        columnIndex : 'TRANSFORMATIONS_USAGE',
        width       : '10%',
        sorter      : true,
        align       : "center"
    }
];

const cloudinaryReportsData = ( { model } ) => {

    if (!model)
    {
        return [];
    }

    return model.map( cloudinaryReport => {

        return {
            key                   : cloudinaryReport.id,
            date_at               : cloudinaryReport.date_at,
            credits_usage         : cloudinaryReport.credits_usage,
            requests              : cloudinaryReport.requests,
            transformations_usage : cloudinaryReport.transformations_usage,
        };

    });

};


const CloudinaryReportTableMain = {
    columns : cloudinaryReportsColumns,
    data    : cloudinaryReportsData,
}

export default CloudinaryReportTableMain;