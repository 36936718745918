import InstructionFields from "../fields";
import { Localize } from "components/service";
import { timeFormat } from "utils/helper-functions";

let instructionsColumns = [
  // {
  //   title: <Localize>TABLES.Column_Title_ID</Localize>,
  //   dataIndex: "id",
  //   columnIndex: "ID",
  //   align: "center",
  //   width: "5%",
  // },
  {
    title: <Localize>TABLES.Column_Title_Title</Localize>,
    dataIndex: "menu_title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Author</Localize>,
    dataIndex: "relAuthor",
    columnIndex: "REL_AUTHOR",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Attachments</Localize>,
    dataIndex: "attachments_total",
    columnIndex: "ATTACHMENTS_TOTAL",
    align: "center",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_ShowMenu</Localize>,
    dataIndex: "menu_show",
    columnIndex: "MENU_SHOW",
    align: "center",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_CreateDate</Localize>,
    dataIndex: "created_at",
    columnIndex: "CREATED_AT",
    sorter: true,
  },
  {
    // title: 'action',
    dataIndex: "action",
    align: "center",
    width: "4%",
  },
];

const instructionsData = (model, _, variables) => {
  if (!model) {
    return [];
  }

  return model.map((instruction) => {
    return {
      key: instruction.id,
      // id: instruction.id,
      menu_title: <InstructionFields.Title instruction={instruction} />,
      relAuthor: <InstructionFields.Info instruction={instruction} />,
      attachments_total: instruction.attachments_total ?? (
        <strong>&mdash;</strong>
      ),
      menu_show: instruction.menu_show ? (
        <strong className="color-green">Yes</strong>
      ) : (
        <strong className="color-red">No</strong>
      ),
      created_at: timeFormat(instruction.created_at),
      action: (
        <InstructionFields.Action
          instruction={instruction}
          variables={variables}
        />
      ),
    };
  });
};

const InstructionsTableHelper = () => {
  return {
    columns: instructionsColumns,
    data: instructionsData,
  };
};

export default InstructionsTableHelper;
