import React from "react";
import { Switch, Route } from 'react-router-dom';
import UsersRoute from './user/users-route';
import PostsRoute from './post/posts-route';
import CommentsRoute from './comment/comments-route';
import HashtagsRoute from './hashtag/hashtags-route';
import CollectionsRoute from './collection/collections-route';
import IstructionsRoute from './instruction/instructions-route'
import CloudinaryReportsRoute from "./cloudinary-report/cloudinary-reports-route";
import SettingsRoute from './settings/settings-route';

import { Pages } from "components/pages";


const AppRoutes = () => {

    return (

        <Switch>
            <Route path="/" exact>
                <Pages.Dashboard />
            </Route>

            <Route path="/users">
                <UsersRoute />
            </Route>

            <Route path="/posts">
                <PostsRoute />
            </Route>

            <Route path="/all-comments">
                <CommentsRoute />
            </Route>

            <Route path="/hashtags">
                <HashtagsRoute />
            </Route>

            <Route path="/collections">
                <CollectionsRoute />
            </Route>

            <Route path="/instructions">
                <IstructionsRoute />
            </Route>

            <Route path="/cloudinary-reports">
                <CloudinaryReportsRoute />
            </Route>

            <Route path="/settings">
                <SettingsRoute />
            </Route>

            <Route path="*">
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default AppRoutes;