import React   from 'react';
import { Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Localize } from "components/service";


const TotalItems = ( { paginatorInfo, extraclass } ) => {

    return ( 
        <div className={ `row ${ extraclass }` }>

            <div className="col d-flex justify-content-end">

                <Tag color="default"><Localize>GL_Text_OnThePage</Localize> : <span>{ paginatorInfo != null ? paginatorInfo.count : <LoadingOutlined /> }</span></Tag>
                <Tag color="default"><Localize>GL_Text_Total</Localize> : <span>{ paginatorInfo != null ? paginatorInfo.total : <LoadingOutlined /> }</span></Tag>
                    
            </div>

        </div>
    );

}

export default TotalItems;