import React from "react";
import { Form, Button, Input } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import InstructionFields from "../../fields";
import { instructionUseMutation } from "../../hooks";
import { Localize } from "components/service";

const InstructionAttachmentForm = ({
  documentCreate = false,
  attachment,
  variables,
  action,
}) => {
  const [form] = Form.useForm();
  const attachmentID = +attachment?.id;

  const { _setAttachmentCreateUpdate, loadingMutationAttachmentCreateUpdate } =
    instructionUseMutation.attachmentCreateUpdate({ attachmentID, variables });

  const { _setMutationInstructionAttachmentDelete } =
    instructionUseMutation.attachmentDelete(attachmentID, documentCreate);

  const deleteFile = () => {
    if (documentCreate) {
      _setMutationInstructionAttachmentDelete({
        variables: {
          id: attachmentID,
        },
      });
    }
    action();
  };

  return (
    <Form
      key="edit-attachment-form"
      layout="vertical"
      form={form}
      className="model-form edit-attachment-form"
      onFinish={(values) => {
        _setAttachmentCreateUpdate({
          variables: {
            input: {
              id: attachmentID ? +attachment.id : undefined,
              ...values,
            },
          },
        });

        action();
        form.resetFields();
      }}
    >
      <span className="custom-close-modal-btn" onClick={deleteFile}>
        <CloseOutlined />
      </span>

      <div style={{ marginBottom: "10px" }}>
        <InstructionFields.Attachment attachment={attachment} />
      </div>

      <Form.Item
        name="title"
        label={<Localize>FORMS.Input_Label_Title</Localize>}
        initialValue={attachment?.title}
      >
        <Input name="title" />
      </Form.Item>

      <Form.Item
        name="description"
        label={<Localize>FORMS.Input_Label_Description</Localize>}
        initialValue={attachment?.description}
      >
        <Input.TextArea
          maxLength={300}
          placeholder={
            Localize({ children: "FORMS.Input_Placeholder_ShortDescription" })
              .props.children
          }
          autoSize={{ minRows: 3, maxRows: 5 }}
          // showCount={
          //     { formatter: ({ count, maxLength }) => maxLength - count }
          // }
        />
      </Form.Item>

      <div className="form-btn-holder">
        <Button
          className="ant-btn ant-btn-secondary bg-light-gray"
          onClick={deleteFile}
        >
          <Localize>GLOBAL.Button_Text_Cancel</Localize>
        </Button>

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          loading={loadingMutationAttachmentCreateUpdate}
        >
          {documentCreate ? (
            <Localize>GLOBAL.Button_Text_AddFile</Localize>
          ) : (
            <Localize>GLOBAL.Button_Text_Save</Localize>
          )}
        </Button>
      </div>
    </Form>
  );
};

export default InstructionAttachmentForm;
