import { gql } from '@apollo/client';

export const cloudinaryReportFieldsFull = gql`
    fragment cloudinaryReportFieldsFull on CloudinaryReport {
        id
        date_at
        type
        transformations_usage
        transformations_credits_usage
        objects_usage
        bandwidth_usage
        bandwidth_credits_usage
        storage_usage
        storage_credits_usage
        requests
        credits_usage
        credits_limit
        credits_used_percent
        created_at
        updated_at
    }
`;