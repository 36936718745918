import React, { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import { GET_INSTRUCTIONS } from "graphql/query/instruction-gql";

import Instruction from "components/instruction";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";
import "./instruction.scss";

const basePath = Instruction.Const.basePath;
const tableHelper = Instruction.Helpers.TableMain();

const InstructionsPage = () => {
  useActiveMenuItem(["instructions"], ["instructions"]);

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Instructions" }).props
        .children,
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  let match = useRouteMatch(`${basePath}/page/:pageNum`);

  // const objOrderBy = [{ column: "ID", order: "ASC" }];

  const [searchText /*setSearchText*/] = useState("");
  const [filters /*setFilters*/] = useState();
  const [resetSorting /*setResetSorting*/] = useState(false);

  const [variables, setVariables] = useState({});

  const instructionsFilters = JSON.parse(
    localStorage.getItem("instructionsFilters")
  );
  // const instructionsSorting = JSON.parse(
  //   localStorage.getItem("instructionsSorting")
  // );

  const { _setInstructionOrderSet } = Instruction.Hooks.instructionOrderSet({
    variables,
  });

  return (
    <PageWrapDefault
      className="page-instructions"
      dataExist={true}
      title={<Localize>PAGES.Title_Instructions</Localize>}
      staticPath=""
    >
      <Tables.DragSorting
        model="instructions"
        extraClass="table-instructions"
        query={GET_INSTRUCTIONS}
        routeUrl={`${basePath}`}
        currentPage={match ? parseInt(match.params.pageNum) : 1}
        searchText={searchText}
        setVariables={setVariables}
        mutationOrderSet={_setInstructionOrderSet}
        objFilter={filters || gqlBuilderWhere(instructionsFilters)}
        // objOrderBy={instructionsSorting || objOrderBy}
        objOrderBy={[ { column: "MENU_ORDER", order: "ASC" } ]}
        sortingName="instructionsSorting"
        tableHelper={tableHelper}
        resetSorting={resetSorting}
      >
        <div className="table-action-bar">
          <div className="col">
            {/* <Instruction.Filters.Table
              filters={filters}
              setSearchText={setSearchText}
              setFilters={setFilters}
              instructionsFilters={instructionsFilters}
              setResetSorting={setResetSorting}
              objOrderBy={objOrderBy}
            /> */}
          </div>

          <div className="col">
            <Link className="ant-btn ant-btn-primary" to={`${basePath}/create`}>
              <span>
                <PlusOutlined />
                <Localize>INSTRUCTION.Button_Text_Create</Localize>
              </span>
            </Link>
          </div>
        </div>
      </Tables.DragSorting>
    </PageWrapDefault>
  );
};

export default InstructionsPage;
