import React from 'react';
import { Column } from '@ant-design/plots';
import moment from 'moment';




const Chart = ({ data }) => {

    const config = {
        height: 250,
        data,
        meta: {
            date: {
                formatter: (oldDate) => {
                    return moment(oldDate).format("DD.MM");
                }
            }
        },
        color: '#E5027D',
        xField: 'date',
        yField: 'per_day',
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
        yAxis: {
            title: {
                text: 'per day'
            }
        },
        label: {
            visible: true,
        },

    };

    return <Column { ...config } />;
};

export default Chart;


