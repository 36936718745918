import useMutationInstructionCreateUpdate from "./instruction-use-mutation-create-update";
import useMutationInstructionDelete from "./instruction-use-mutation-delete";
import useMutationEventAttachmentCreateUpdate from "./instruction-attachment-use-mutation-create-update";
import useMutationInstructionAttachmentDelete from "./instruction-attachment-use-mutation-delete";
import useMutationInstructionAttachmentOrderSet from "./instruction-attachment-use-mutation-order-set";
import useMutationInstructionInstructionOrderSet from "./instruction-use-mutation-order-set ";

export const instructionUseMutation = {
  createUpdate: useMutationInstructionCreateUpdate,
  delete: useMutationInstructionDelete,
  attachmentCreateUpdate: useMutationEventAttachmentCreateUpdate,
  attachmentDelete: useMutationInstructionAttachmentDelete,
  attachmentOrderSet: useMutationInstructionAttachmentOrderSet,
  instructionOrderSet: useMutationInstructionInstructionOrderSet,
};
