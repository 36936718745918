import InstructionFields from "./fields";
import InstructionForms from "./forms";
import InstructionConst from "./instruction-const";
import InstructionHelpers from "./helpers";
import InstructionFilters from "./filters";
import { instructionUseMutation } from "./hooks";

const Instruction = {
  Filters: InstructionFilters,
  Fields: InstructionFields,
  Forms: InstructionForms,
  Const: InstructionConst,
  Helpers: InstructionHelpers,
  Hooks: instructionUseMutation,
};

export default Instruction;
