import { gql } from '@apollo/client';


export const COMMENT_DELETE = gql`
    mutation CommentDelete ($id: ID!) {
        commentDelete(id: $id) {
            id
            author {
              id
            }
            post {
              id
            }
        }
    }
`;