import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { INSTRUCTION_DELETE } from "graphql/mutation/instruction-gql";
import InstructionConst from "../instruction-const";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationInstructionDelete = (id) => {
  const history = useHistory();

  const [_setMutationInstructionDelete, { loading }] = useMutation(
    INSTRUCTION_DELETE,
    {
      update(cache, { data }) {
        const {
          instructionDelete: { label, message },
        } = data;

        history.push(InstructionConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Instruction" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationInstructionDelete,
    loadingMutationInstructionDelete: loading,
  };
};

export default useMutationInstructionDelete;
