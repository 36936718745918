import React from "react";
import { Form, DatePicker, Button } from "antd";

import { Localize }           from "components/service";
import { initialDateBetween } from "utils/helper-functions";


const { RangePicker } = DatePicker;

const CloudinaryReportFormFilterMain = ({ action, modelsFilters, setCount, setFilters, setFilterList, filterList, filterInit, gqlBuilderWhere, modelFilters, formFieldsHide }) => {

    return (

        <div className="ant-form ant-form-vertical filters-form">

            { formFieldsHide.includes('DATE_AT') ? null :
                <Form.Item
                    initialValue = { initialDateBetween(modelsFilters, 'DATE_AT') }
                    name         = "DATE_AT"
                    label        = { <Localize>DATE</Localize> }
                >

                    <RangePicker
                        separator   = "&ndash;"
                        placeholder = { [ Localize({ children: "FORMS.Input_Placeholder_StartDate" }).props.children, Localize({ children: "FORMS.Input_Placeholder_EndDate" }).props.children ] }
                        onChange    = { (_, dateSrings) =>
                            dateSrings[ 0 ] !== '' ?
                                setFilterList({
                                    ...filterList,
                                    DATE_AT: {
                                        column: "DATE_AT",
                                        operator: "BETWEEN",
                                        value: dateSrings
                                    }
                                }) : setFilterList({
                                    ...filterList,
                                    DATE_AT: {}
                                }) } 
                    />

                </Form.Item>
            }

            <div className="form-btn-holder">

                <Button
                    type     = "primary"
                    htmlType = "submit"
                    onClick  = { () => {
                        setFilters(gqlBuilderWhere({ ...filterInit, ...modelsFilters, ...filterList }));
                        setCount({ ...modelsFilters, ...filterList });
                        action();
                    } }
                >
                    <Localize>FORMS.Button_Text_ApplyFilters</Localize>
                </Button>

                <Button
                    className = "ant-btn-secondary bg-light-gray btn-right"
                    htmlType  = "submit"
                    onClick   = { () => {
                        setFilterList({});
                        setCount({});
                        setFilters(gqlBuilderWhere({}));
                        localStorage.removeItem(modelFilters);
                    } }
                >
                    <Localize>FORMS.Button_Text_ResetFilters</Localize>
                </Button>

            </div>
        </div>
    )
}

export default CloudinaryReportFormFilterMain;