import UserInfoField from "./user-info-field";
import UserActionField from "./user-action-field";
import UserContactField from "./user-contact-field";
import UserTopPostsField from "./user-post-top-field";
import UserInstructionViewsField from "./user-instruction-views-field";
import UserInstructionAttachmentViewsField from "./user-instruction-attachment-views-field";
import UserInstructionReadAllField from "./user-instruction-read-all-field";

const UserFields = {
  Info: UserInfoField,
  Action: UserActionField,
  Contact: UserContactField,
  TopPosts: UserTopPostsField,
  InstructionViews: UserInstructionViewsField,
  InstructionAttachmentViews: UserInstructionAttachmentViewsField,
  InstructionReadAll: UserInstructionReadAllField,
};

export default UserFields;
