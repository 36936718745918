import React from "react";
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'antd';
import UserConst from '../users-const';
import Icons from "components/icons";
import { Localize } from "components/service";


const UserInfoField = ({ user }) => {

    const path = useLocation().pathname.split('/')[ 1 ];

    const { sendPassword, id, name, salutation, job_type } = user;

    function getTranslationInitValue(type) {
        switch (type)
        {
            case 'CEO':
                return <Localize>FORMS.Select_OptionLabel_CEO</Localize>

            case 'CSellingOwner':
                return <Localize>FORMS.Select_OptionLabel_CSellingOwner</Localize>;

            case 'BranchManager':
                return <Localize>FORMS.Select_OptionLabel_BranchManager</Localize>;

            case 'SalesManager':
                return <Localize>FORMS.Select_OptionLabel_SalesManager</Localize>;

            case 'CertifiedCarSalesman':
                return <Localize>FORMS.Select_OptionLabel_CertifiedCarSalesman</Localize>;

            case 'Salesperson':
                return <Localize>FORMS.Select_OptionLabel_Salesperson</Localize>;

            case 'ServiceConsultant':
                return <Localize>FORMS.Select_OptionLabel_ServiceConsultant</Localize>;

            default:
                return '';
        }
    }

    return (

        <div className='flex-field-box'>
            {
                sendPassword?.created_at && path === 'users' &&

                <span className='col'>
                    <Tooltip overlayClassName="model-tooltip" destroyTooltipOnHide={ true } title={ sendPassword?.message }>
                        <span className='icon'>
                            <Icons.Padlock />
                        </span>
                    </Tooltip>
                </span>
            }

            <span className='col'>
                <Link to={ `${ UserConst.basePath }/${ id }` }> { `${ salutation ? salutation : '' } ${ name }` }</Link>
                { job_type && <div>{ getTranslationInitValue(job_type) }</div> }
            </span>
        </div>
    )
}


export default UserInfoField;