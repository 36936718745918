import React from "react";
import { Form, Input, Button, Switch } from "antd";
import { instructionUseMutation } from "../../hooks";
import { Localize, EntityRemoveButton } from "components/service";

const InstructionEditForm = ({ instruction }) => {
  const [form] = Form.useForm();

  const {
    _setInstructionCreateUpdate,
    loadingMutationInstructionCreateUpdate,
  } = instructionUseMutation.createUpdate(instruction?.id);

  const { _setMutationInstructionDelete, loadingMutationInstructionDelete } =
    instructionUseMutation.delete(instruction?.id);

  return (
    <Form
      key="edit-instruction-form"
      layout="vertical"
      form={form}
      className="model-form edit-instruction-form"
      onFinish={(values) => {
        _setInstructionCreateUpdate({
          variables: {
            input: {
              id: instruction?.id ? +instruction.id : undefined,
              ...values,
            },
          },
        });
      }}
    >
      <div className="row-grid grid-gap-10 col-auto-150">
        <Form.Item
          name="menu_show"
          label={<Localize>FORMS.Input_Label_ShowMenu</Localize>}
          initialValue={instruction?.menu_show}
          className="form-item-switch"
        >
          <Switch defaultChecked={instruction?.menu_show} />
        </Form.Item>

        <Form.Item
          name="company_rules"
          label={<Localize>FORMS.Input_Label_CompanyRules</Localize>}
          initialValue={instruction?.company_rules}
          className="form-item-switch"
        >
          <Switch defaultChecked={instruction?.company_rules} />
        </Form.Item>
      </div>

      <Form.Item
        name="menu_title"
        label={<Localize>FORMS.Input_Label_MenuTitle</Localize>}
        initialValue={instruction?.menu_title}
        rules={[
          {
            required: true,
            message: <Localize>FORM_RULES.Required_Password</Localize>,
          },
        ]}
      >
        <Input name="title" />
      </Form.Item>

      <Form.Item
        name="title"
        label={<Localize>FORMS.Input_Label_Title</Localize>}
        initialValue={instruction?.title}
      >
        <Input name="title" />
      </Form.Item>

      <Form.Item
        name="description"
        label={<Localize>FORMS.Input_Label_Description</Localize>}
        initialValue={instruction?.description}
      >
        <Input.TextArea
          //   maxLength={800}
          // placeholder={ Localize({ children: "FORMS.Input_Placeholder_ShortDescription" }).props.children }
          autoSize={{ minRows: 20, maxRows: 999 }}
          // showCount={
          //     { formatter: ({ count, maxLength }) => maxLength - count }
          // }
        />
      </Form.Item>
      <div className="form-btn-holder">
        {instruction?.id && (
          <EntityRemoveButton
            modalButton={
              <Button>
                <Localize>INSTRUCTION.Button_Text_Delete</Localize>{" "}
              </Button>
            }
            nameEntity={
              Localize({ children: "ENTITY.Modal_Title_Instruction" }).props
                .children
            }
            dataNameEntity={instruction?.title ?? ""}
            loading={loadingMutationInstructionDelete}
            deleteMutation={_setMutationInstructionDelete}
            variables={{
              id: instruction?.id,
            }}
          />
        )}

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          loading={loadingMutationInstructionCreateUpdate}
        >
          {instruction?.id ? (
            <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
          ) : (
            <Localize>INSTRUCTION.Button_Text_Create</Localize>
          )}
        </Button>
      </div>
    </Form>
  );
};

export default InstructionEditForm;
