import React from "react";
import { useQuery } from "@apollo/client";
import { GET_INSTRUCTION } from "graphql/query/instruction-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import InstructionRoute from "components/routes/instruction/instruction-route";
import Instruction from "components/instruction";
import { Localize } from "components/service";

const InstructionPage = ({ match, history }) => {
  useActiveMenuItem(["instructions"], ["instructions"]);

  const path = history.location.pathname.split('/').pop()
  const instructionID = match ? parseInt(match.params.id) : null;

  const { data: { instruction } = {}, loading } = useQuery(GET_INSTRUCTION, {
    skip: !instructionID,
    variables: {
      id: instructionID,
    },
  });

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props
        .children,
      path: "overview",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Attachments" }).props
        .children,
      path: "attachments",
    },
  ];

  return (
    <PageWrapDefault
      className="page-instruction"
      loading={loading}
      title={
        path === "create" ? (
          <Localize>PAGES.Title_CreateInstruction</Localize>
        ) : !loading && instruction ? (
          instruction.menu_title
        ) : (
          "Page 404"
        )
      }
      dataExist={!loading}
      pageNavbar={ !loading && instruction ? pageNavbar : false }
      staticPath={`${Instruction.Const.basePath}/${instructionID}`}
    >
      <InstructionRoute
        instruction={instruction}
        history={history}
        loading={loading}
      />
    </PageWrapDefault>
  );
};

export default InstructionPage;
