import CommentFields from "./fields";
import CommentConst from "./comment-const";
import CommentHelpers from "./helpers";
import { commentUseMutation } from "./hooks";


const Comment = {
  Fields: CommentFields,
  Const: CommentConst,
  Helpers: CommentHelpers,
  Hooks: commentUseMutation
}

export default Comment;