import React from 'react';
import Icon from '@ant-design/icons';


const AnyFileSvg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0" d="M39,13.9l-8.7-8.6C30.2,5.1,30,5,29.7,5H10.9C10.4,5,10,5.4,10,5.8v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.5C39.3,14.3,39.2,14,39,13.9z M30.7,8l5.7,5.6h-5.7V8z M11.7,41.3V6.7h17.2v7.8c0,0.5,0.4,0.9,0.9,0.9
		h7.8v25.9H11.7z"/>
	</svg>
);

const DOCSvg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0" d="M39.1,13.9l-8.7-8.6C30.3,5.1,30.1,5,29.8,5H11c-0.5,0-0.9,0.4-0.9,0.9v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.5C39.4,14.2,39.3,14,39.1,13.9z M30.7,8l5.7,5.6h-5.7V8z M11.8,41.2V6.7H29v7.8c0,0.5,0.4,0.9,0.9,0.9
		h7.8v25.9H11.8z"/>
		<path style={ { fill: "#008ED1" } } d="M21.8,18.9H7.2c-1.4,0-2.6-1.1-2.6-2.6v-4.8c0-1.4,1.1-2.6,2.6-2.6h14.6c1.4,0,2.6,1.1,2.6,2.6v4.8
		C24.3,17.7,23.2,18.9,21.8,18.9z"/>
		<path className="st0"
			d="M28.2,23.5H15.7c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h12.5c0.5,0,0.9,0.4,0.9,0.9S28.6,23.5,28.2,23.5z" />
		<path className="st0"
			d="M33.9,27.4H15.7c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h18.2c0.5,0,0.9,0.4,0.9,0.9S34.3,27.4,33.9,27.4z" />
		<path className="st0" d="M33.9,31.3H15.7c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h18.2c0.5,0,0.9,0.4,0.9,0.9
		C34.7,30.9,34.3,31.3,33.9,31.3z"/>
		<path className="st0" d="M33.9,35.2H15.7c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h18.2c0.5,0,0.9,0.4,0.9,0.9
		C34.7,34.8,34.3,35.2,33.9,35.2z"/>
		<g>
			<path style={ { fill: "#FFFFFF" } } d="M11.8,14c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.1-0.6,0.2-0.9,0.2
			H7.8v-4.6h1.7c0.3,0,0.7,0.1,0.9,0.2c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.4,0.4,0.5,0.7C11.8,13.3,11.8,13.6,11.8,14z M11,14
			c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.6-0.1H8.6v3.2h0.9
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.5C10.9,14.5,11,14.2,11,14z"/>
			<path style={ { fill: "#FFFFFF" } } d="M16.9,14c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.1-0.6,0.2-0.9,0.2
			c-0.3,0-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.3-0.2-0.6-0.2-0.9s0.1-0.6,0.2-0.9
			c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.4,0.7-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.7,0.1,0.9,0.2c0.3,0.1,0.5,0.3,0.7,0.5
			c0.2,0.2,0.4,0.5,0.5,0.7C16.8,13.3,16.9,13.6,16.9,14z M16,14c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.3-0.5
			c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.3,0.5
			c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.2,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1
			s0.4,0,0.6-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.5C16,14.5,16,14.2,16,14z"/>
			<path style={ { fill: "#FFFFFF" } } d="M20.7,15.2c0,0,0.1,0,0.1,0.1l0.3,0.4c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.1-0.6,0.2-1,0.2
			c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.3-0.5-0.4-0.7c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9
			c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.7-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.6,0.1,0.9,0.2c0.3,0.1,0.5,0.3,0.7,0.4
			l-0.3,0.4c0,0,0,0-0.1,0.1s-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.3-0.1-0.5-0.1
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.1,0.7c0,0.3,0,0.5,0.1,0.7
			c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.2,0.4,0.3s0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.2-0.1
			c0.1,0,0.1-0.1,0.2-0.2C20.5,15.2,20.6,15.2,20.7,15.2C20.6,15.2,20.6,15.2,20.7,15.2z"/>
		</g>
	</svg>
);

const XLSSvg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0" d="M39.2,13.9l-8.7-8.6C30.3,5.1,30.1,5,29.9,5H11c-0.5,0-0.9,0.4-0.9,0.9v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.5C39.4,14.3,39.3,14,39.2,13.9z M30.8,8l5.7,5.6h-5.7V8z M11.9,41.3V6.7H29v7.8c0,0.5,0.4,0.9,0.9,0.9
		h7.8v25.9H11.9z"/>
		<path style={ { fill: "#008E47" } } d="M21.8,18.9H7.3c-1.4,0-2.6-1.1-2.6-2.6v-4.8c0-1.4,1.1-2.6,2.6-2.6h14.6c1.4,0,2.6,1.1,2.6,2.6v4.8
		C24.4,17.7,23.2,18.9,21.8,18.9z"/>
		<g>
			<path style={ { fill: "#FFFFFF" } } d="M10.8,13.9l-1.5-2.2h0.8c0.1,0,0.1,0,0.1,0c0,0,0,0,0.1,0.1l1,1.7c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1l0.9-1.5
			c0-0.1,0.1-0.1,0.2-0.1h0.8l-1.5,2.2l1.5,2.4h-0.8c-0.1,0-0.1,0-0.1,0s-0.1-0.1-0.1-0.1l-1.1-1.7c0,0,0,0,0,0.1c0,0,0,0,0,0.1
			l-1,1.6c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0H9.3L10.8,13.9z"/>
			<path style={ { fill: "#FFFFFF" } } d="M14.8,15.6h1.8v0.7H14v-4.6h0.8V15.6z" />
			<path style={ { fill: "#FFFFFF" } } d="M19.7,12.6C19.6,12.6,19.6,12.6,19.7,12.6c-0.1,0.1-0.1,0.1-0.2,0.1s-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1
			c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0.1-0.2,0.1s-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.3,0.1,0.4,0.2
			c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.2,0.3s0.1,0.3,0.1,0.5c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5
			c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.7,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.3-0.1-0.4-0.2
			c-0.1-0.1-0.2-0.2-0.3-0.2l0.2-0.4c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1
			c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.1,0-0.2-0.1-0.3
			c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.2-0.2-0.2-0.4C17.1,13.4,17,13.2,17,13c0-0.2,0-0.3,0.1-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.5-0.3
			c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.5,0,0.7,0.1c0.2,0.1,0.4,0.2,0.6,0.4L19.7,12.6z"/>
		</g>
		<path className="st0" d="M32,22.3H17.7c-0.5,0-0.9,0.4-0.9,0.9v12.8c0,0.5,0.4,0.9,0.9,0.9H32c0.5,0,0.9-0.4,0.9-0.9V23.1
		C32.9,22.7,32.5,22.3,32,22.3z M31.1,26.4H23V24h8.1V26.4z M23,28.1h8.1v2.4H23V28.1z M21.3,30.5h-2.8v-2.4h2.8V30.5z M21.3,24v2.4
		h-2.8V24H21.3z M18.6,32.3h2.8V35h-2.8V32.3z M23,35v-2.8h8.1V35H23z"/>
	</svg>
);

const PPTSvg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0" d="M39.1,13.9l-8.7-8.6C30.2,5.1,30,5,29.8,5H10.9c-0.5,0-0.9,0.4-0.9,0.9v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.5C39.3,14.3,39.2,14.1,39.1,13.9z M30.7,8l5.7,5.6h-5.7V8z M11.8,41.3V6.8H29v7.8c0,0.5,0.4,0.9,0.9,0.9
		h7.8v25.9H11.8z"/>
		<path style={ { fill: "#C6451B" } } d="M21.7,18.9H7.2c-1.4,0-2.6-1.1-2.6-2.6v-4.8C4.6,10.1,5.8,9,7.2,9h14.6c1.4,0,2.6,1.1,2.6,2.6v4.8
		C24.3,17.8,23.1,18.9,21.7,18.9z"/>
		<g>
			<path style={ { fill: "#FFFFFF" } } d="M10.3,14.7v1.6H9.5v-4.6H11c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.5
			c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.8,0.1H10.3z
			 M10.3,14H11c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3
			c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.6V14z"/>
			<path style={ { fill: "#FFFFFF" } } d="M14.3,14.7v1.6h-0.8v-4.6h1.5c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.5
			c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.8,0.1H14.3z
			 M14.3,14h0.6c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3
			c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.6V14z"/>
			<path style={ { fill: "#FFFFFF" } } d="M20.5,11.8v0.7h-1.4v3.9h-0.8v-3.9h-1.4v-0.7H20.5z" />
		</g>
		<path className="st0" d="M30.7,26.3c-0.3,0-0.7-0.2-0.8-0.5c-0.5-1.2-1.5-2.3-2.7-2.8c-0.4-0.2-0.6-0.7-0.4-1.2
		c0.2-0.4,0.7-0.6,1.2-0.4c1.6,0.8,2.9,2.1,3.5,3.7c0.2,0.4,0,0.9-0.5,1.1C30.9,26.3,30.8,26.3,30.7,26.3z"/>
		<path className="st0" d="M24.9,35.2c-4,0-7.2-3.2-7.2-7.2s3.2-7.2,7.2-7.2c0,0,0.1,0,0.1,0c0.5,0,0.8,0.4,0.8,0.9v5.6h5.4
		c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.2,0.6C32,32,28.8,35.2,24.9,35.2z M24.1,22.5c-2.7,0.4-4.7,2.7-4.7,5.5
		c0,3,2.5,5.5,5.5,5.5c2.7,0,5-2,5.4-4.5H25c-0.5,0-0.9-0.4-0.9-0.9V22.5z"/>
	</svg>
);

const PDFSvg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0"
			d="M33.9,24h-8.1c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h8.1c0.5,0,0.9,0.4,0.9,0.9S34.4,24,33.9,24z" />
		<path className="st0"
			d="M33.9,27.9h-8.1c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h8.1c0.5,0,0.9,0.4,0.9,0.9S34.4,27.9,33.9,27.9z" />
		<path className="st0"
			d="M33.9,31.8h-8.1c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h8.1c0.5,0,0.9,0.4,0.9,0.9S34.4,31.8,33.9,31.8z" />
		<path className="st0" d="M33.9,35.8H15.8c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9h18.1c0.5,0,0.9,0.4,0.9,0.9
		C34.8,35.4,34.4,35.8,33.9,35.8z"/>
		<path className="st0" d="M39.1,13.9l-8.7-8.6C30.2,5.1,30,5,29.8,5H10.9c-0.5,0-0.9,0.4-0.9,0.9v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.5C39.3,14.3,39.2,14.1,39.1,13.9z M30.7,8l5.7,5.6h-5.7V8z M11.8,41.3V6.7H29v7.8c0,0.5,0.4,0.9,0.9,0.9
		h7.8v25.9H11.8z"/>
		<path style={ { fill: "#ED1C24" } } d="M21.7,18.9H7.2c-1.4,0-2.6-1.1-2.6-2.6v-4.8C4.6,10.1,5.8,9,7.2,9h14.6c1.4,0,2.6,1.1,2.6,2.6v4.8
		C24.3,17.7,23.1,18.9,21.7,18.9z"/>
		<g>
			<path style={ { fill: "#FFFFFF" } } d="M9.4,14.7v1.6H8.5v-4.6H10c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.5
			c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.8,0.1H9.4z
			 M9.4,14H10c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3
			c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H9.4V14z"/>
			<path style={ { fill: "#FFFFFF" } } d="M16.5,14c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.7,0.5s-0.6,0.2-0.9,0.2h-1.7
			v-4.6h1.7c0.3,0,0.7,0.1,0.9,0.2c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.4,0.4,0.5,0.7C16.4,13.4,16.5,13.7,16.5,14z M15.6,14
			c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.3-0.5c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.4-0.1-0.6-0.1h-0.9v3.2h0.9
			c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.5C15.6,14.5,15.6,14.3,15.6,14z"/>
			<path style={ { fill: "#FFFFFF" } } d="M20,11.7v0.7h-2v1.3h1.7v0.7H18v1.9h-0.9v-4.6H20z" />
		</g>
		<path className="st0" d="M22.2,31.9h-6.7c-0.5,0-0.9-0.4-0.9-0.9v-8c0-0.5,0.4-0.9,0.9-0.9h6.7c0.5,0,0.9,0.4,0.9,0.9v8
		C23.1,31.5,22.7,31.9,22.2,31.9z M16.4,30.2h5v-6.3h-5V30.2z"/>
	</svg>
)

const JPGSvg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0" d="M39,13.8l-8.7-8.6c-0.2-0.2-0.4-0.3-0.6-0.3H10.9c-0.5,0-0.9,0.4-0.9,0.9v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.4C39.3,14.2,39.2,14,39,13.8z M30.6,7.9l5.7,5.6h-5.7V7.9z M11.7,41.2V6.7h17.2v7.8
		c0,0.5,0.4,0.9,0.9,0.9h7.8v25.9H11.7z"/>
		<path style={ { fill: "#A9CF38" } } d="M21.7,18.8H7.1c-1.4,0-2.6-1.1-2.6-2.6v-4.8c0-1.4,1.1-2.6,2.6-2.6h14.6c1.4,0,2.6,1.1,2.6,2.6v4.8
		C24.2,17.7,23.1,18.8,21.7,18.8z"/>
		<g>
			<path style={ { fill: "#FFFFFF" } } d="M10.9,14.6c0,0.3,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.1-0.3,0.3-0.5,0.3c-0.2,0.1-0.4,0.1-0.7,0.1
			c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3-0.1l0-0.5c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0
			c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.3,0-0.4v-3h0.8V14.6z"/>
			<path style={ { fill: "#FFFFFF" } } d="M12.7,14.6v1.6h-0.8v-4.6h1.5c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.5
			c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.8,0.1H12.7z
			 M12.7,13.9h0.6c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3
			c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.6V13.9z"/>
			<path style={ { fill: "#FFFFFF" } } d="M17.9,15.6c0.2,0,0.4,0,0.5-0.1c0.1,0,0.3-0.1,0.4-0.1v-0.8h-0.6c-0.1,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1v-0.5
			h1.5v1.8c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.3,0.1-0.5,0.1c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6-0.1-0.9-0.2
			c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7
			c0.2-0.2,0.5-0.4,0.7-0.5c0.3-0.1,0.6-0.2,1-0.2c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.4l-0.2,0.4c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.3,0.5s-0.1,0.4-0.1,0.7c0,0.3,0,0.5,0.1,0.7
			c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.2,0.5,0.3C17.5,15.6,17.7,15.6,17.9,15.6z"/>
		</g>
		<path className="st0" d="M32.3,35H17.4c-0.3,0-0.6-0.2-0.8-0.4c-0.2-0.3-0.1-0.6,0-0.9l4.2-6.8c0.2-0.2,0.4-0.4,0.7-0.4
		c0.3,0,0.6,0.1,0.7,0.4l1.7,2.3l2.8-4.4c0.2-0.3,0.5-0.4,0.8-0.4c0.3,0,0.6,0.2,0.7,0.5l4.8,8.9c0.1,0.3,0.1,0.6,0,0.9
		C32.9,34.8,32.6,35,32.3,35z M19,33.3h11.9l-3.4-6.4l-2.3,3.7l0.3,0.4c0.3,0.4,0.2,0.9-0.2,1.2c-0.4,0.3-0.9,0.2-1.2-0.2l-2.3-3.2
		L19,33.3z"/>
		<path className="st0" d="M22,25.7c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C24.5,24.6,23.4,25.7,22,25.7z
		 M22,22.5c-0.4,0-0.7,0.3-0.7,0.7S21.6,24,22,24s0.7-0.3,0.7-0.7S22.4,22.5,22,22.5z"/>
	</svg>
)

const PNGSvg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0" d="M39.1,13.9l-8.7-8.6c-0.2-0.2-0.4-0.3-0.6-0.3H11c-0.5,0-0.9,0.4-0.9,0.9v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.6C39.4,14.3,39.3,14.1,39.1,13.9z M30.7,8.1l5.7,5.6h-5.7V8.1z M11.8,41.3V6.8H29v7.8
		c0,0.5,0.4,0.9,0.9,0.9h7.8v25.9H11.8z"/>
		<path style={ { fill: "#2CBDB7" } } d="M21.8,18.9H7.2c-1.4,0-2.6-1.1-2.6-2.6v-4.8C4.6,10.2,5.8,9,7.2,9h14.6c1.4,0,2.6,1.1,2.6,2.6v4.8
		C24.3,17.8,23.2,18.9,21.8,18.9z"/>
		<g>
			<path style={ { fill: "#FFFFFF" } } d="M9.2,14.7v1.6H8.4v-4.6h1.5c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.5
			c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.8,0.1H9.2z
			 M9.2,14.1h0.6c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3
			c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1H9.2V14.1z"/>
			<path style={ { fill: "#FFFFFF" } } d="M12.8,11.8C12.8,11.8,12.8,11.8,12.8,11.8c0.1,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0.1l2.4,3c0-0.1,0-0.1,0-0.2
			c0-0.1,0-0.1,0-0.2v-2.8h0.7v4.6h-0.4c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1-0.1l-2.4-3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2v2.8
			h-0.7v-4.6L12.8,11.8C12.7,11.8,12.8,11.8,12.8,11.8z"/>
			<path style={ { fill: "#FFFFFF" } } d="M19.1,15.7c0.2,0,0.4,0,0.5-0.1s0.3-0.1,0.4-0.1v-0.8h-0.6c-0.1,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1v-0.5h1.5v1.8
			c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.3,0.1-0.5,0.1c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6-0.1-0.9-0.2
			c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.7,0.2-0.9c0.1-0.3,0.3-0.5,0.5-0.7
			c0.2-0.2,0.5-0.4,0.7-0.5c0.3-0.1,0.6-0.2,1-0.2c0.4,0,0.7,0.1,1,0.2c0.3,0.1,0.5,0.3,0.7,0.4l-0.2,0.4c0,0.1-0.1,0.1-0.2,0.1
			c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0
			c-0.2,0-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.3,0.5s-0.1,0.4-0.1,0.7c0,0.3,0,0.5,0.1,0.7
			c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.2,0.5,0.3C18.7,15.7,18.9,15.7,19.1,15.7z"/>
		</g>
		<path className="st0" d="M32.4,35.1H17.5c-0.3,0-0.6-0.2-0.8-0.4c-0.2-0.3-0.1-0.6,0-0.9L21,27c0.2-0.2,0.4-0.4,0.7-0.4
		c0.3,0,0.6,0.1,0.7,0.4l1.7,2.3l2.8-4.4c0.2-0.3,0.5-0.4,0.8-0.4c0.3,0,0.6,0.2,0.7,0.5l4.8,8.9c0.1,0.3,0.1,0.6,0,0.9
		S32.7,35.1,32.4,35.1z M19.1,33.4H31L27.6,27l-2.3,3.7l0.3,0.4c0.3,0.4,0.2,0.9-0.2,1.2c-0.4,0.3-0.9,0.2-1.2-0.2L21.8,29
		L19.1,33.4z"/>
		<path className="st0" d="M22.1,25.8c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5S23.5,25.8,22.1,25.8z M22.1,22.6
		c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7S22.5,22.6,22.1,22.6z"/>
	</svg>
)

const MP3Svg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0" d="M39.1,13.9l-8.7-8.6C30.2,5.1,30,5,29.7,5H10.9C10.4,5,10,5.4,10,5.9v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.5C39.3,14.3,39.2,14.1,39.1,13.9z M30.7,8l5.7,5.6h-5.7V8z M11.8,41.3V6.7h17.2v7.8
		c0,0.5,0.4,0.9,0.9,0.9h7.8v25.9H11.8z"/>
		<path style={ { fill: "#F99D1C" } } d="M21.7,18.9H7.1c-1.4,0-2.6-1.1-2.6-2.6v-4.8C4.6,10.1,5.7,9,7.1,9h14.6c1.4,0,2.6,1.1,2.6,2.6v4.8
		C24.3,17.8,23.1,18.9,21.7,18.9z"/>
		<g>
			<path style={ { fill: "#FFFFFF" } } d="M10.1,14.6c0,0.1,0.1,0.1,0.1,0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2l1.3-2.5c0,0,0-0.1,0.1-0.1
			c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0h0.6v4.6h-0.7v-2.9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l-1.4,2.6
			c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1L8.4,13c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.1,0,0.2v2.9H7.7v-4.6h0.6c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0.1l1.4,2.5
			C10,14.5,10.1,14.6,10.1,14.6z"/>
			<path style={ { fill: "#FFFFFF" } } d="M14.4,14.7v1.6h-0.8v-4.6h1.5c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.5
			c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.8,0.1H14.4z
			 M14.4,14h0.6c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3
			c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.6V14z"/>
			<path style={ { fill: "#FFFFFF" } } d="M19,11.7c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.1,0.3,0.1,0.5
			c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.1-0.1,0.2-0.2,0.2s-0.2,0.1-0.3,0.1c0.3,0.1,0.5,0.2,0.6,0.4s0.2,0.4,0.2,0.7
			c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.4,0-0.6-0.1
			c-0.2-0.1-0.3-0.1-0.4-0.2s-0.2-0.2-0.3-0.4c-0.1-0.1-0.2-0.3-0.2-0.5l0.3-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0
			c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1
			c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.2
			c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.3-0.1-0.6-0.1v-0.6c0.2,0,0.3,0,0.5-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1-0.1,0.1-0.1,0.2-0.2
			c0-0.1,0-0.2,0-0.3c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.3,0-0.5,0.1c-0.1,0.1-0.2,0.2-0.3,0.4
			c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0L17.5,13c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.3,0.3-0.4
			c0.1-0.1,0.3-0.2,0.5-0.2S18.8,11.7,19,11.7z"/>
		</g>
		<path className="st0" d="M30.6,21.4c-0.2-0.2-0.5-0.2-0.7-0.2l-8.7,1.7c-0.4,0.1-0.7,0.4-0.7,0.8v7.4c-0.3-0.1-0.6-0.2-0.9-0.2
		c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6c1.4,0,2.5-1.1,2.6-2.4h0v-9.3l7-1.4v6.1C28.9,29,28.6,29,28.3,29c-1.4,0-2.6,1.2-2.6,2.6
		c0,1.4,1.2,2.6,2.6,2.6c1.3,0,2.4-0.9,2.6-2.2h0V22C30.9,21.8,30.8,21.5,30.6,21.4z M19.6,34.5c-0.5,0-0.9-0.4-0.9-0.9
		c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9C20.5,34.1,20.1,34.5,19.6,34.5z M28.3,32.5c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9
		c0.5,0,0.9,0.4,0.9,0.9C29.2,32.1,28.8,32.5,28.3,32.5z"/>
	</svg>
)

const MP4Svg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0" d="M39,13.9l-8.7-8.6C30.2,5.1,30,5,29.7,5H10.9C10.4,5,10,5.4,10,5.8v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.5C39.3,14.3,39.2,14,39,13.9z M30.7,8l5.7,5.6h-5.7V8z M11.7,41.3V6.7h17.2v7.8c0,0.5,0.4,0.9,0.9,0.9
		h7.8v25.9H11.7z"/>
		<path style={ { fill: "#7B3594" } } d="M21.7,18.9H7.1c-1.4,0-2.6-1.1-2.6-2.6v-4.8c0-1.4,1.1-2.6,2.6-2.6h14.6c1.4,0,2.6,1.1,2.6,2.6v4.8
		C24.3,17.7,23.1,18.9,21.7,18.9z"/>
		<g>
			<path style={ { fill: "#FFFFFF" } } d="M10.1,14.6c0,0.1,0.1,0.1,0.1,0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2l1.3-2.5c0,0,0-0.1,0.1-0.1
			c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0h0.6v4.6h-0.7v-2.9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l-1.4,2.6
			c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1l-1.4-2.6c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.1,0,0.2v2.9H7.7v-4.6h0.6c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0.1l1.4,2.5
			C10,14.4,10,14.5,10.1,14.6z"/>
			<path style={ { fill: "#FFFFFF" } } d="M14.4,14.7v1.6h-0.8v-4.6h1.5c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0.1,0.3,0.3,0.3,0.5
			c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.8,0.1H14.4z
			 M14.4,14h0.6c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3
			c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.6V14z"/>
			<path style={ { fill: "#FFFFFF" } } d="M20,14.6h0.6V15c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0H20v1.1h-0.7v-1.1h-1.9c-0.1,0-0.1,0-0.1,0
			c0,0-0.1-0.1-0.1-0.1l-0.1-0.4l2.2-2.9H20V14.6z M19.3,13.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2l-1.4,1.9h1.4V13.1z"/>
		</g>
		<path className="st0" d="M24.8,37.2c-4.5,0-8.2-3.7-8.2-8.2c0-4.5,3.7-8.2,8.2-8.2c4.5,0,8.2,3.7,8.2,8.2C33,33.6,29.3,37.2,24.8,37.2z
		 M24.8,22.5c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5c3.6,0,6.5-2.9,6.5-6.5C31.3,25.4,28.4,22.5,24.8,22.5z"/>
		<path className="st0" d="M23.4,32.2c-0.1,0-0.3,0-0.4-0.1c-0.3-0.2-0.4-0.4-0.4-0.7v-4.6c0-0.3,0.2-0.6,0.4-0.7c0.3-0.2,0.6-0.2,0.9,0
		l4,2.3c0.3,0.2,0.4,0.4,0.4,0.7c0,0.3-0.2,0.6-0.4,0.7l-4,2.3C23.7,32.2,23.6,32.2,23.4,32.2z M24.3,28.2v1.6l1.4-0.8L24.3,28.2z"/>
	</svg>
)

const MOVSvg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0" d="M39.1,13.9l-8.7-8.6C30.2,5.1,30,5,29.8,5H11c-0.5,0-0.9,0.4-0.9,0.9v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.5C39.4,14.2,39.3,14,39.1,13.9z M30.7,8l5.7,5.6h-5.7V8z M11.8,41.2V6.7H29v7.8c0,0.5,0.4,0.9,0.9,0.9
		h7.8v25.9H11.8z"/>
		<path style={ { fill: "#B93192" } } d="M22.6,18.9H7.2c-1.4,0-2.6-1.1-2.6-2.6v-4.8c0-1.4,1.1-2.6,2.6-2.6h15.4c1.4,0,2.6,1.1,2.6,2.6v4.8
		C25.2,17.7,24,18.9,22.6,18.9z"/>
		<g>
			<path style={ { fill: "#FFFFFF" } } d="M10.1,14.6c0,0.1,0.1,0.1,0.1,0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2l1.3-2.5c0,0,0-0.1,0.1-0.1
			c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0h0.6v4.6H12v-2.9c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2l-1.4,2.6
			c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1l-1.4-2.6c0,0.1,0,0.1,0,0.2
			c0,0.1,0,0.1,0,0.2v2.9H7.8v-4.6h0.6c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0.1l1.4,2.5
			C10.1,14.4,10.1,14.5,10.1,14.6z"/>
			<path style={ { fill: "#FFFFFF" } } d="M18,14c0,0.3-0.1,0.6-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.1-0.6,0.2-0.9,0.2
			c-0.3,0-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.3-0.7-0.5c-0.2-0.2-0.4-0.5-0.5-0.7c-0.1-0.3-0.2-0.6-0.2-0.9s0.1-0.6,0.2-0.9
			c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.4,0.7-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.7,0.1,0.9,0.2c0.3,0.1,0.5,0.3,0.7,0.5
			c0.2,0.2,0.4,0.5,0.5,0.7C17.9,13.3,18,13.6,18,14z M17.1,14c0-0.3,0-0.5-0.1-0.7c-0.1-0.2-0.2-0.4-0.3-0.5s-0.3-0.2-0.5-0.3
			c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.6,0.1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.2-0.1,0.4-0.1,0.7
			c0,0.2,0,0.5,0.1,0.7c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.1s0.4,0,0.6-0.1
			c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.3,0.3-0.5C17.1,14.5,17.1,14.2,17.1,14z"/>
			<path style={ { fill: "#FFFFFF" } } d="M18,11.7h0.7c0.1,0,0.1,0,0.2,0.1s0.1,0.1,0.1,0.1l1.1,2.8c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.2,0.1,0.3
			c0-0.2,0.1-0.5,0.2-0.6l1.1-2.8c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.1h0.7l-1.8,4.6h-0.8L18,11.7z"/>
		</g>
		<path className="st0" d="M24.9,37.2c-4.5,0-8.2-3.7-8.2-8.2c0-4.5,3.7-8.2,8.2-8.2s8.2,3.7,8.2,8.2C33.1,33.5,29.4,37.2,24.9,37.2z
		 M24.9,22.5c-3.6,0-6.5,2.9-6.5,6.5c0,3.6,2.9,6.5,6.5,6.5s6.5-2.9,6.5-6.5C31.4,25.4,28.4,22.5,24.9,22.5z"/>
		<path className="st0" d="M23.5,32.2c-0.1,0-0.3,0-0.4-0.1c-0.3-0.2-0.4-0.4-0.4-0.7v-4.6c0-0.3,0.2-0.6,0.4-0.7c0.3-0.2,0.6-0.2,0.9,0
		l4,2.3c0.3,0.2,0.4,0.4,0.4,0.7c0,0.3-0.2,0.6-0.4,0.7l-4,2.3C23.8,32.2,23.6,32.2,23.5,32.2z M24.3,28.2v1.6l1.4-0.8L24.3,28.2z"/>
	</svg>
)

const ZIPSvg = () => (
	<svg fill="currentColor" width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
		<path className="st0" d="M39,13.8l-8.7-8.6C30.2,5,30,5,29.7,5H10.9C10.4,5,10,5.3,10,5.8v36.3c0,0.5,0.4,0.9,0.9,0.9h27.5
		c0.5,0,0.9-0.4,0.9-0.9V14.5C39.3,14.2,39.2,14,39,13.8z M30.7,8l5.7,5.6h-5.7V8z M18.8,11.3h-1.3c-0.5,0-0.9,0.4-0.9,0.9
		s0.4,0.9,0.9,0.9h1.3v10.3h-1.3c-0.5,0-0.9,0.4-0.9,0.9S17,25,17.5,25h1.3v4.3h-1.3c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9
		h1.3v4.3h-1.3c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9h1.3v4.2h-3.1v-1.1h0.8c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9
		h-0.8v-4.3h0.8c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9h-0.8v-4.3h0.8c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-0.8v-4.3h0.8
		c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-0.8v-4.3h0.8c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9h-0.8V6.7h3.1V11.3z M11.7,6.7h2.2
		v34.5h-2.2V6.7z M37.6,41.2H20.5V6.7h8.4v7.8c0,0.5,0.4,0.9,0.9,0.9h7.8V41.2z"/>
		<path style={ { fill: "#888A8C" } } d="M21.9,18.6H7.3c-1.4,0-2.6-1.1-2.6-2.6v-4.8c0-1.4,1.1-2.6,2.6-2.6h14.6c1.4,0,2.6,1.1,2.6,2.6v4.8
		C24.4,17.5,23.3,18.6,21.9,18.6z"/>
		<g>
			<path style={ { fill: "#FFFFFF" } } d="M13.4,11.5v0.3c0,0.1,0,0.2-0.1,0.3L11,15.3h2.4V16H9.9v-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1l2.3-3.3h-2.3
			v-0.7H13.4z"/>
			<path style={ { fill: "#FFFFFF" } } d="M15,16h-0.9v-4.6H15V16z" />
			<path style={ { fill: "#FFFFFF" } } d="M16.9,14.4V16h-0.8v-4.6h1.5c0.3,0,0.6,0,0.8,0.1c0.2,0.1,0.4,0.2,0.6,0.3s0.3,0.3,0.3,0.5
			c0.1,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.1-0.8,0.1H16.9z
			 M16.9,13.8h0.6c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3
			c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.3-0.1-0.4-0.1h-0.6V13.8z"/>
		</g>
	</svg>
)

const DocumentIcon = props => {

	let svg;

	switch (props.type?.toLowerCase())
	{
		case "doc": case "docx":
			svg = DOCSvg;
			break;
		case "xls": case "xlsx":
			svg = XLSSvg;
			break;
		case "ppt": case "pptx":
			svg = PPTSvg;
			break;
		case "pdf": svg = PDFSvg; break;
		case "jpg": case "jpeg": svg = JPGSvg; break;
		case "png": svg = PNGSvg; break;
		case "mp3": svg = MP3Svg; break;
		case "mp4": svg = MP4Svg; break;
		case "mov": svg = MOVSvg; break;
		case "zip": case "7z": svg = ZIPSvg; break;
		default: svg = AnyFileSvg;
	}

	return <Icon component={ svg } className={ props.className } style={ props.style } />;

};


export default DocumentIcon;