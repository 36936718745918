import InstructionInfoField from "./instruction-info-field";
import InstructionActionField from "./instruction-action-field";
import InstructionTitleField from "./instruction-title-field";
import InstructionAttachmentField from "./instruction-attachment-field";
import InstructionAttachmentActionField from "./instruction-attachment-action-field";

const InstructionFields = {
  Info: InstructionInfoField,
  Action: InstructionActionField,
  Title: InstructionTitleField,
  Attachment: InstructionAttachmentField,
  AttachmentAction: InstructionAttachmentActionField,
};

export default InstructionFields;
