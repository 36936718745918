import React from "react";
import { Link } from "react-router-dom";
import User from "components/user";

const InstructionInfoField = ({ instruction }) => {
  const { id, name, nickname } = instruction?.relAuthor;

  return (
    <>
      <Link to={`${User.Const.basePath}/${id}`}>
        {`${name}`} <br /> {`${nickname}`}
      </Link>
    </>
  );
};

export default InstructionInfoField;
