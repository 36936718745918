import React, { useState } from "react";
import { useRouteMatch }   from "react-router-dom";

import { GET_CLOUDINARY_REPORTS } from "graphql/query/cloudinary-report-gql";

import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables }           from "components/layout";
import { Localize }        from "components/service";
import { gqlBuilderWhere } from "utils";
import CloudinaryReport    from "components/cloudinary-report";


const basePath    = CloudinaryReport.Const.basePath;
const tableHelper = CloudinaryReport.Tables.Main;

const CloudinaryReportsPage = () => {

    useActiveMenuItem([ "cloudinary-reports" ], [ "cloudinary-reports" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "CLOUDINARY_REPORT_Titles" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs( breadcrumbs );

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const objOrderBy = [ { column: "CREATED_AT", order: "DESC" } ];

    const [ searchText, setSearchText ]     = useState( "" );
    const [ filters, setFilters ]           = useState();
    const [ resetSorting, setResetSorting ] = useState( false );

    const cloudinaryReportsFilters = JSON.parse( localStorage.getItem( "cloudinaryReportsFilters" ) );
    const cloudinaryReportsSorting = JSON.parse( localStorage.getItem( "cloudinaryReportsSorting" ) );

    return (
        <PageWrapDefault
            className  = "page-posts"
            dataExist  = { true }
            title      = { <Localize>CLOUDINARY_REPORT_Titles</Localize> }
            staticPath = ""
        >
            <Tables.Main
                model        = "cloudinaryReports"
                extraClass   = "table-cloudinary-reports"
                query        = { GET_CLOUDINARY_REPORTS }
                varSet       = { { perPage: 30 } }
                routeUrl     = { `${ basePath }` }
                currentPage  = { match ? parseInt( match.params.pageNum ) : 1 }
                searchText   = { searchText }
                objFilter    = { filters || gqlBuilderWhere( cloudinaryReportsFilters ) }
                objOrderBy   = { cloudinaryReportsSorting || objOrderBy }
                sortingName  = "cloudinaryReportsSorting"
                tableHelper  = { tableHelper }
                resetSorting = { resetSorting }
                totalCounter = { true }
                marginY      = "my-0"
            >
                <div className="table-action-bar">

                    <div className="col">
                        <CloudinaryReport.Drawers.FilterMain
                            filters         = { filters }
                            setSearchText   = { setSearchText }
                            setFilters      = { setFilters }
                            modelsFilters   = { cloudinaryReportsFilters }
                            setResetSorting = { setResetSorting }
                            objOrderBy      = { objOrderBy }
                        />
                    </div>

                    <div className="col">
                        <CloudinaryReport.Elements.General />
                    </div>
                    
                </div>

            </Tables.Main>
        </PageWrapDefault>
    );
};

export default CloudinaryReportsPage;