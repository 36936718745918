import { LoginPage, ForgotPasswordPage } from "./authorization";
import { UsersPage, UserPage } from "./user";
import { PostsPage, PostPage } from "./post";
import { CommentsPage } from "./comment";
import { InstructionsPage, InstructionPage } from "./instruction";
import { HashtagsPage, HashtagPage } from "./hashtag";
import { CollectionsPage, CollectionPage } from "./collection";
import CloudinaryPages from "./cloudinary-report";
import { SettingsPage } from "./settings";
import Dashboard from "./dashboard";
import Page404 from "./404";


import "./pages.scss";



export const Pages = {
    Dashboard,
    Users: UsersPage,
    User: UserPage,
    Posts: PostsPage,
    Post: PostPage,
    Comments: CommentsPage,
    Hashtags: HashtagsPage,
    Hashtag: HashtagPage,
    Collections: CollectionsPage,
    Collection: CollectionPage,
    Cloudinary: CloudinaryPages,
    Settings: SettingsPage,
    Instructions: InstructionsPage,
    Instruction: InstructionPage,
    Page404
};

export const GuestPages = {
    LoginPage,
    ForgotPasswordPage,
}