import { gql } from '@apollo/client';


export const GET_COMMENTS = gql`
    query GetComments(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryCommentsOrderByOrderByClause!]
        $where: QueryCommentsWhereWhereConditions
    ){
      comments(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
              id
              author {
                id
                avatar
                name
                nickname
                initials
              }
              post {
                id
                title
              }
              content
              like_count
              created_at
              updated_at
            }
        }
    }
`;