import React from "react";
import { Tooltip } from "antd";
import { Localize } from "components/service";
import { timeFormat } from "utils/helper-functions";

const UserInstructionReadAllField = ({ user }) =>
  user.instr_attachs_read_all ? (
    <Tooltip
      overlayClassName="model-tooltip"
      destroyTooltipOnHide={true}
      title={`Read all at: ${timeFormat(user?.instr_attachs_read_all_at)}`}
    >
      <strong className="color-green">
        <Localize>FORMS.Select_OptionLabel_Yes</Localize>
      </strong>
    </Tooltip>
  ) : (
    <strong className="color-red">
      <Localize>FORMS.Select_OptionLabel_No</Localize>
    </strong>
  );

export default UserInstructionReadAllField;
