import React from "react";
import { Menu, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { instructionUseMutation } from "../hooks";
import InstructionForms from "../forms";

import Icons from "components/icons";
import {
  EntityRemoveButton,
  ModalStandard,
  Localize,
} from "components/service";

const InstructionAttachmentActionField = ({ attachment, variables }) => {
  const attachmentID = attachment?.id;

  const {
    _setMutationInstructionAttachmentDelete,
    loadingMutationInstructionAttachmentDelete,
  } = instructionUseMutation.attachmentDelete(attachmentID);

  let items = [
    {
      key: "1",
      icon: (
        <ModalStandard
          closable={false}
          width={380}
          extraClass={"modal-form"}
          modalButton={
            <span
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Icons.Edit /> <Localize>MENUS_DROP.Label_Edit</Localize>
            </span>
          }
        >
          <strong className="modal-title">
            <Localize>FORMS.Title_EditFile</Localize>
          </strong>
          <InstructionForms.Attachment
            attachment={attachment}
            variables={variables}
          />
        </ModalStandard>
      ),
    },
    {
      key: "2",
      icon: (
        <EntityRemoveButton
          modalButton={
            <span
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            Localize({ children: "ENTITY.Modal_Title_Document" }).props.children
          }
          dataNameEntity={attachment?.server_name}
          loading={loadingMutationInstructionAttachmentDelete}
          deleteMutation={_setMutationInstructionAttachmentDelete}
          variables={{
            id: attachmentID,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      overlay={<Menu items={items} />}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default InstructionAttachmentActionField;
