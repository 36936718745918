import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Select, Input } from "antd";

import { USER_META_SET } from "graphql/mutation/user-gql";

import { successNotification, errorNotification } from "components/request-result";
import { userNormalMeta } from "utils"
import { Localize } from "components/service";
import Icons from "components/icons";

const { Option } = Select;




const UserJobProfileForm = ({ user }) => {


    const [ form ] = Form.useForm();

    let metaFields = userNormalMeta(user?.meta_fields);

    const {
        jobDescription = {},
        companyDepartment = {},
        contactPhone = {},
        contactEmail = {},
        workingOn = {}
    } = metaFields;

    const [ userMetaSet, { loading } ] = useMutation(USER_META_SET,
        {
            update(cache, { data }) {

                const {
                    userMetaSet: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            }
        });

    return (
        <Form
            className="edit-profile-form"
            layout="vertical"
            form={ form }
            onFinish={ (values) => {

                let inputValues = [];

                for (let [ key, meta_value ] of Object.entries(values))
                {

                    let [ meta_key, meta_type ] = key.split('-');

                    inputValues.push({
                        meta_key,
                        meta_type,
                        meta_value
                    });
                }

                userMetaSet({
                    variables: {
                        user_id: user.id ?? undefined,
                        input: [ ...inputValues ]
                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            } }>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
                <Form.Item
                    name={ `contactPhone-string${ !!contactPhone ? `-${ contactPhone.id }` : false }` }
                    label={ <Localize>FORMS.Input_Label_ContactPhone</Localize> }
                    initialValue={ !!contactPhone ? contactPhone.meta_value : "" }
                    rules={ [
                        {
                            type: "string",
                            pattern: /^[\d\-+ ]+$/,
                            message: <Localize>FORM_RULES.ContactPhone</Localize>
                        },
                    ] }
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={ `contactEmail-string${ !!contactEmail ? `-${ contactEmail.id }` : false }` }
                    label={ <Localize>FORMS.Input_Label_ContactEmail</Localize> }
                    initialValue={ !!contactEmail ? contactEmail.meta_value : "" }
                    rules={ [
                        {
                            type: 'email',
                            message: <Localize>FORM_RULES.Email</Localize>,
                        }
                    ] }
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={ `companyDepartment-string${ !!companyDepartment ? `-${ companyDepartment.id }` : false }` }
                    label={ <Localize>FORMS.Input_Label_Department</Localize> }
                    initialValue={ !!companyDepartment ? companyDepartment.meta_value : undefined }
                >
                    <Select
                        showSearch
                        suffixIcon={ <Icons.Arrow /> }
                        className="select-serch-input"
                        popupClassName="select-serch-dropdown"
                        optionFilterProp="value"
                    >
                        <Option value="Management">
                            <Localize>FORMS.Select_OptionLabel_Management</Localize>
                        </Option>
                        <Option value="ITManagement">
                            <Localize>FORMS.Select_OptionLabel_ITManagement</Localize>
                        </Option>
                        <Option value="LegalCounsel">
                            <Localize>FORMS.Select_OptionLabel_LegalCounsel</Localize>
                        </Option>
                        <Option value="FinancialAccounting">
                            <Localize>FORMS.Select_OptionLabel_FinancialAccounting</Localize>
                        </Option>
                        <Option value="MarketingAndSales">
                            <Localize>FORMS.Select_OptionLabel_MarketingAndSales</Localize>
                        </Option>
                        <Option value="SystemStandConstruction">
                            <Localize>FORMS.Select_OptionLabel_SystemStandConstruction</Localize>
                        </Option>
                        <Option value="IndividualStandConstruction">
                            <Localize>FORMS.Select_OptionLabel_IndividualStandConstruction</Localize>
                        </Option>
                        <Option value="WarehousingAndLogistics">
                            <Localize>FORMS.Select_OptionLabel_WarehousingAndLogistics</Localize>
                        </Option>
                        <Option value="ConstructionManagement">
                            <Localize>FORMS.Select_OptionLabel_ConstructionManagement</Localize>
                        </Option>
                        <Option value="AssemblyAndTransport">
                            <Localize>FORMS.Select_OptionLabel_AssemblyAndTransport</Localize>
                        </Option>
                        <Option value="Draft">
                            <Localize>FORMS.Select_OptionLabel_Draft</Localize>
                        </Option>
                        <Option value="CentralPurchasing">
                            <Localize>FORMS.Select_OptionLabel_CentralPurchasing</Localize>
                        </Option>
                        <Option value="Secretariat">
                            <Localize>FORMS.Select_OptionLabel_Secretariat</Localize>
                        </Option>
                        <Option value="Personnel">
                            <Localize>FORMS.Select_OptionLabel_Personnel</Localize>
                        </Option>
                    </Select>
                </Form.Item>


                <Form.Item
                    name={ `jobDescription-string${ !!jobDescription ? `-${ jobDescription.id }` : false }` }
                    label={ <Localize>FORMS.Input_Label_JobDescription</Localize> }
                    initialValue={ !!jobDescription ? jobDescription.meta_value : undefined }
                >
                    <Select
                        showSearch
                        suffixIcon={ <Icons.Arrow /> }
                        className="select-serch-input"
                        popupClassName="select-serch-dropdown"
                        optionFilterProp="value"
                    >
                        <Option value="CEO">
                            <Localize>FORMS.Select_OptionLabel_CEO</Localize>
                        </Option>
                        <Option value="CTO">
                            <Localize>FORMS.Select_OptionLabel_CTO</Localize>
                        </Option>
                        <Option value="PM">
                            <Localize>FORMS.Select_OptionLabel_PM</Localize>
                        </Option>
                        <Option value="PMCalculator">
                            <Localize>FORMS.Select_OptionLabel_PMCalculator</Localize>
                        </Option>
                        <Option value="PMCADDraftsman">
                            <Localize>FORMS.Select_OptionLabel_PMCADDraftsman</Localize>
                        </Option>
                        <Option value="CADDraftsman">
                            <Localize>FORMS.Select_OptionLabel_CADDraftsman</Localize>
                        </Option>
                        <Option value="ProjectAssistant">
                            <Localize>FORMS.Select_OptionLabel_ProjectAssistant</Localize>
                        </Option>
                        <Option value="DevelopmentTeamLead">
                            <Localize>FORMS.Select_OptionLabel_DevelopmentTeamLead</Localize>
                        </Option>
                        <Option value="SeniorBackendDeveloper">
                            <Localize>FORMS.Select_OptionLabel_SeniorBackendDeveloper</Localize>
                        </Option>
                        <Option value="SoftwareDeveloper">
                            <Localize>FORMS.Select_OptionLabel_SoftwareDeveloper</Localize>
                        </Option>
                        <Option value="SystemAdministrator">
                            <Localize>FORMS.Select_OptionLabel_SystemAdministrator</Localize>
                        </Option>
                        <Option value="LogisticsEmployee">
                            <Localize>FORMS.Select_OptionLabel_LogisticsEmployee</Localize>
                        </Option>
                        <Option value="HeadSystemsStandConstruction">
                            <Localize>FORMS.Select_OptionLabel_HeadSystemsStandConstruction</Localize>
                        </Option>
                        <Option value="HeadIndividualStandConstruction">
                            <Localize>FORMS.Select_OptionLabel_HeadIndividualStandConstruction</Localize>
                        </Option>
                        <Option value="FinancialAccountantReceivable">
                            <Localize>FORMS.Select_OptionLabel_FinancialAccountantReceivable</Localize>
                        </Option>
                        <Option value="FinancialAccountantPayable">
                            <Localize>FORMS.Select_OptionLabel_FinancialAccountantPayable</Localize>
                        </Option>
                        <Option value="FinancialAccountantHR">
                            <Localize>FORMS.Select_OptionLabel_FinancialAccountantHR</Localize>
                        </Option>
                        <Option value="SalesMarketing">
                            <Localize>FORMS.Select_OptionLabel_SalesMarketing</Localize>
                        </Option>
                        <Option value="Clerk">
                            <Localize>FORMS.Select_OptionLabel_Clerk</Localize>
                        </Option>
                        <Option value="Secretary">
                            <Localize>FORMS.Select_OptionLabel_Secretary</Localize>
                        </Option>
                    </Select>
                </Form.Item>
            </div>

            <Form.Item
                name={ `workingOn-string${ !!workingOn ? `-${ workingOn.id }` : false }` }
                label={ <Localize>FORMS.Input_Label_Description</Localize> }
                initialValue={ !!workingOn ? workingOn.meta_value : "" }
            >
                <Input.TextArea
                    maxLength={ 128 }
                    autoSize={ { minRows: 2, maxRows: 3 } }
                    placeholder={ Localize({ children: "FORMS.Input_Placeholder_ShortDescription" }).props.children }
                />
            </Form.Item>

            <div className="form-btn-holder">
                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
                </Button>
            </div>

        </Form>
    );
};


export default UserJobProfileForm;