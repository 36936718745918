import UserChangePasswordForm from "./user-change-password-form";
import UserEditForm from "./user-edit-form";
import UserJobProfileForm from "./user-job-profile-form";
import UserAddForm from "./user-add-form";
import UserFiltersForm from "./user-filters-form";
import UserFormItems from "./fields";


import './forms.scss';

const UserForms = {
    Fields: UserFormItems,
    ChangePassword: UserChangePasswordForm,
    Edit: UserEditForm,
    JobProfile: UserJobProfileForm,
    Add: UserAddForm,
    Filter: UserFiltersForm,
};

export default UserForms;
