import { Link } from "react-router-dom";
import { SortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import Instruction from "components/instruction";

const InstructionTitleField = ({ instruction, dragHandle = true }) => {
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: "grab",
        color: "#999",
        padding: "15px 20px",
      }}
    />
  ));

  return (
    <div className="d-flex align-items-center">
      {dragHandle && <DragHandle />}

      <Link to={`${Instruction.Const.basePath}/${instruction.id}`}>
        {instruction?.menu_title ?? <strong>&mdash;</strong>}
      </Link>
    </div>
  );
};

export default InstructionTitleField;
