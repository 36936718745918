import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { instructionUseMutation } from "../hooks";
import { EntityRemoveButton, Localize } from "components/service";
import InstructionConst from "../instruction-const";
import Icons from "components/icons";

const InstructionActionField = ({ instruction }) => {
  const history = useHistory();

  const { _setMutationInstructionDelete, loadingMutationInstructionDelete } =
    instructionUseMutation.delete(instruction.id);

  let items = [
    {
      key: "edit",
      label: <Localize>MENUS_DROP.Label_Edit</Localize>,
      icon: <Icons.Edit />,
      // disabled: true,
      onClick: () =>
        history.push(`${InstructionConst.basePath}/${instruction.id}`),
    },
    {
      key: "delete",
      disabled: false,
      icon: (
        <>
          {" "}
          {false ? (
            <span style={{ display: "flex", alignItems: "center" }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          ) : (
            <EntityRemoveButton
              modalButton={
                <span style={{ display: "flex", alignItems: "center" }}>
                  <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
                </span>
              }
              nameEntity={
                Localize({ children: "ENTITY.Modal_Title_Instruction" }).props
                  .children
              }
              dataNameEntity={instruction.title}
              loading={loadingMutationInstructionDelete}
              deleteMutation={_setMutationInstructionDelete}
              variables={{
                id: instruction.id,
              }}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default InstructionActionField;
