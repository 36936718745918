import { gql } from "@apollo/client";

export const INSTRUCTION_CREATE = gql`
  mutation InstructionCreate($input: InstructionFieldsInput!) {
    instructionCreate(input: $input) {
      label
      message
      instruction {
        id
        title
        menu_title
        description
        author_id
        menu_show
        menu_order
        views
        created_at
        updated_at
      }
    }
  }
`;

export const INSTRUCTION_UPDATE = gql`
  mutation InstructionUpdate($input: InstructionFieldsInput!) {
    instructionUpdate(input: $input) {
      label
      message
      instruction {
        id
        title
        menu_title
        description
        author_id
        menu_show
        menu_order
        views
        created_at
        updated_at
      }
    }
  }
`;

export const INSTRUCTION_DELETE = gql`
  mutation InstructionDelete($id: ID!) {
    instructionDelete(id: $id) {
      label
      message
    }
  }
`;

export const INSTRUCTION_ATTACHMENT_CREATE = gql`
  mutation InstructionAttachmentCreate(
    $input: InstructionAttachmentFieldsInput!
  ) {
    instructionAttachmentCreate(input: $input) {
      label
      message
      instruction {
        relInstructionAttachments {
          id
          title
          description
          server_name
        }
      }
    }
  }
`;

export const INSTRUCTION_ATTACHMENT_UPDATE = gql`
  mutation instructionAttachmentUpdate(
    $input: InstructionAttachmentFieldsInput!
  ) {
    instructionAttachmentUpdate(input: $input) {
      label
      message
      instruction {
        relInstructionAttachments {
          id
          title
          description
          server_name
        }
      }
    }
  }
`;

export const INSTRUCTION_ATTACHMENT_DELETE = gql`
  mutation InstructionAttachmentDelete($id: ID!) {
    instructionAttachmentDelete(id: $id) {
      label
      message
      instruction {
        id
        title
        menu_title
        description
        author_id
        menu_show
        menu_order
        views
        created_at
        updated_at
      }
    }
  }
`;

export const INSTRUCTION_ATTCHMENT_ORDER_SET = gql`
  mutation InstructionAttachmentOrderSet($instruction_id: ID!, $ids: [ID!]!) {
    instructionAttachmentOrderSet(instruction_id: $instruction_id, ids: $ids) {
      label
      message
      instructionAttachments {
        id
      }
    }
  }
`;

export const INSTRUCTION_MENU_ORDER_SET = gql`
  mutation InstructionMenuOrderSet($ids: [ID!]!) {
    instructionMenuOrderSet(ids: $ids) {
      label
      message
      instructions {
        id
      }
    }
  }
`;
