import React, { useState, useEffect } from 'react';
import { NumberOutlined, CopyOutlined, FileTextOutlined, FileExclamationOutlined, DashboardOutlined, SettingOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link } from "react-router-dom";
import Icons from "components/icons";
import { Localize } from "components/service";


import './sidebar.scss';


const Sidebar = ({ appParams, setToggleSider }) => {

    let {
        selectedKeys = [ 'dashboard' ],
        openKeys = [ 'dashboard' ]
    } = appParams.activeMenu;

    const [ openSubMenu, setOpenSubMenu ] = useState(openKeys);

    useEffect(() => {

        setOpenSubMenu(openKeys)

    }, [ appParams.activeMenu, openKeys ]);

    const items = [
        {
            key: 'dashboard',
            label: <Link to="/"><DashboardOutlined /><Localize>MENUS.Label_Dashboard</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'users',
            label: <Link to="/users"><Icons.Users /><Localize>MENUS.Label_Users</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'posts',
            label: <Link to="/posts"><FileTextOutlined /><Localize>MENUS.Label_Posts</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'hashtags',
            label: <Link to="/hashtags"><NumberOutlined /><Localize>MENUS.Label_Hashtags</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'collections',
            label: <Link to="/collections"><CopyOutlined /><Localize>MENUS.Label_Collections</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'instructions',
            label: <Link to="/instructions"><FileExclamationOutlined /><Localize>MENUS.Label_Instructions</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'cloudinary-reports',
            label: <Link to="/cloudinary-reports"><VideoCameraOutlined /><Localize>CLOUDINARY_REPORT_Titles</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
        {
            key: 'settings',
            label: <Link to="/settings"><SettingOutlined /> <Localize>MENUS.Label_Settings</Localize></Link>,
            onClick: () => setToggleSider(true),
        },
    ];


    return (
        <>
            <Menu
                items={ items }
                className='sidebar-menu'
                theme="dark"
                mode="inline"
                selectedKeys={ selectedKeys }
                openKeys={ openSubMenu }
                onOpenChange={ (keys) => setOpenSubMenu(keys) }
                style={ { borderRight: 0 } }>
            </Menu>
        </>
    );
};

export default Sidebar;