import React from "react";
import { Link } from "react-router-dom";
import Instruction from "components/instruction";

const UserInstructionViewsField = ({ user }) => {
  const instructionViews = user?.relInstructionsViews || [];

  return instructionViews.length ? (
    instructionViews.map(({ id, relInstruction }) => (
      <Link
        key={+id}
        to={`${Instruction.Const.basePath}/${relInstruction.id}`}
        className="tag-main tag-link ellipsis"
      >
        {relInstruction.menu_title}
      </Link>
    ))
  ) : (
    <strong>&mdash;</strong>
  );
};

export default UserInstructionViewsField;
