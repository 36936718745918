
import User from "components/user";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";


const userBasePath = User.Const.basePath;


const UserJobProfileSubPage = ({ user }) => {

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
            path: `${ userBasePath }`
        },
        {
            label: user?.name,
            path: `${ userBasePath }/${ user?.id }`
        },
        {
            label: Localize({ children: "BREADCRUMBS.Label_JobProfile" }).props.children,
            path: `${ userBasePath }/${ user?.id }/profile`
        }
    ]

    useBreadCrumbs(breadcrumbs);


    return (
        <div className="row-grid col-lg-2">

            <div className="col-left">
                <User.Forms.JobProfile user={ user } />
            </div>
            <div className="col-right"></div>
        </div>
    )
}

export default UserJobProfileSubPage;