import { gql } from "@apollo/client";

export const GET_INSTRUCTIONS = gql`
  query instructions(
    $text: String
    $first: Int
    $page: Int
    $orderBy: [QueryInstructionsOrderByOrderByClause!]
    $where: QueryInstructionsWhereWhereConditions
  ) {
    instructions(
      text: $text
      first: $first
      page: $page
      orderBy: $orderBy
      where: $where
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        title
        menu_title
        description
        author_id
        menu_show
        menu_order
        views
        attachments_total
        company_rules
        relAuthor {
          id
          name
          initials
          nickname
        }
        created_at
        updated_at
      }
    }
  }
`;

export const GET_INSTRUCTION = gql`
  query GetInstruction($id: ID) {
    instruction(id: $id) {
      id
      title
      menu_title
      description
      author_id
      menu_show
      menu_order
      views
      company_rules
      relAuthor {
        id
        name
        initials
        nickname
      }
      created_at
      updated_at
    }
  }
`;

export const GET_INSTRUCTION_ATTACHMENTS = gql`
  query GetInstructionAttachments(
    $text: String
    $first: Int
    $page: Int
    $orderBy: [QueryInstructionAttachmentsOrderByOrderByClause!]
    $where: QueryInstructionAttachmentsWhereWhereConditions
    $instruction_id: ID
  ) {
    instructionAttachments(
      text: $text
      first: $first
      page: $page
      orderBy: $orderBy
      where: $where
      instruction_id: $instruction_id
    ) {
      paginatorInfo {
        count
        currentPage
        firstItem
        hasMorePages
        lastItem
        lastPage
        perPage
        total
      }
      data {
        id
        instruction_id
        author_id
        path
        title
        description
        extension
        size
        order
        server_name
        created_at
        updated_at

      }
    }
  }
`;

export const GET_INSTRUCTION_ATTACHMENT = gql`
  query GetInstructionAttachment($id: ID) {
    instructionAttachment(id: $id) {
      id
      instruction_id
      author_id
      path
      title
      description
      extension
      size
      order
      server_name
      created_at
      updated_at
    }
  }
`;
