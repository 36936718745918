import InstructionFields from "../fields";
import { Localize } from "components/service";

const attachmentsColumns = [
  {
    title: <Localize>TABLES.Column_Title_FileName</Localize>,
    dataIndex: "server_name",
    columnIndex: "ORIGINAL_NAME",
    className: "drag-visible",
    // sorter: true
  },
  {
    title: <Localize>TABLES.Column_Title_Title</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    className: "drag-visible",
    // sorter: true
  },
  {
    title: <Localize>TABLES.Column_Title_Description</Localize>,
    dataIndex: "description",
    columnIndex: "DESCRIPTION",
    className: "drag-visible",
    // sorter: true
  },
  {
    // title: 'action',
    dataIndex: "action",
    align: "right",
    width: "4%",
  },
];

const attachmentsData = (model, _, variables) => {
  if (!model) {
    return [];
  }

  return model.map((attachment) => {
    return {
      key: attachment.id,
      server_name: (
        <InstructionFields.Attachment
          attachment={attachment}
          dragHandle={true}
        />
      ),
      title: attachment.title ?? <strong>&mdash;</strong>,
      description: attachment.description ?? <strong>&mdash;</strong>,
      action: (
        <InstructionFields.AttachmentAction
          attachment={attachment}
          variables={variables}
        />
      ),
    };
  });
};

const InstructionAttachmentsTableHelper = {
  columns: attachmentsColumns,
  data: attachmentsData,
};

export default InstructionAttachmentsTableHelper;
