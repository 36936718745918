import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SubPage from "components/pages/instruction/sub-pages";

const InstructionRoute = ({ instruction, history }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/attachments`}>
        <SubPage.Attachments instruction={instruction} />
      </Route>

      <Route path={`${path}/attachments/page/:pageNum`}>
        <SubPage.Attachments instruction={instruction} />
      </Route>

      <Route path={`${path}`}>
        <SubPage.OverView instruction={instruction} history={history} />
      </Route>
    </Switch>
  );
};

export default InstructionRoute;
