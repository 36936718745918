import { useMutation } from "@apollo/client";
import { INSTRUCTION_MENU_ORDER_SET } from "graphql/mutation/instruction-gql";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationInstructionInstructionOrderSet = () => {
  const [_setInstructionOrderSet, { loading }] = useMutation(
    INSTRUCTION_MENU_ORDER_SET,
    {
      update(cache, { data }) {
        const {
          instructionMenuOrderSet: { label, message },
        } = data;

        successNotification({
          title: label,
          description: message,
        });
      },

      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setInstructionOrderSet,
    loadingMutationInstructionOrderSet: loading,
  };
};

export default useMutationInstructionInstructionOrderSet;
