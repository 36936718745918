import React, { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { GET_USERS } from "graphql/query/user-gql";

import User from "components/user";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";
import Icons from "components/icons";
import "./user.scss";

const basePath = User.Const.basePath;
const tableHelper = User.Helpers.TableMain();

const UsersPage = () => {
  useActiveMenuItem(["users"], ["users"]);

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  let match = useRouteMatch(`${basePath}/page/:pageNum`);

  const objOrderBy = [{ column: "ID", order: "DESC" }];

  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState();
  const [resetSorting, setResetSorting] = useState(false);

  const usersFilters = JSON.parse(localStorage.getItem("usersFilters"));
  const usersSorting = JSON.parse(localStorage.getItem("usersSorting"));

    const { _setUserReport } = User.Hooks.report();

  return (
    <PageWrapDefault
      className="page-users"
      dataExist={true}
      title={<Localize>PAGES.Title_Users</Localize>}
      staticPath=""
    >
      <Tables.Main
        model="users"
        query={GET_USERS}
        varSet={{ perPage: 30 }}
        routeUrl={`${basePath}`}
        currentPage={match ? parseInt(match.params.pageNum) : 1}
        searchText={searchText}
        objFilter={filters || gqlBuilderWhere(usersFilters)}
        objOrderBy={usersSorting || objOrderBy}
        sortingName="usersSorting"
        tableHelper={tableHelper}
        resetSorting={resetSorting}
      >
        <div className="table-action-bar">
          <div className="col">
            <User.Filters.Table
              filters={filters}
              setSearchText={setSearchText}
              setFilters={setFilters}
              usersFilters={usersFilters}
              setResetSorting={setResetSorting}
              objOrderBy={objOrderBy}
            />
          </div>

          <div className="col">
            <Button
              //   style={{ maxWidth: "100%" }}
              type="primary"
              onClick={() => _setUserReport()}
            >
              <Icons.Download />{" "}
              <Localize>USER.Button_Text_ReadInstructions</Localize>
            </Button>

            <Link className="ant-btn ant-btn-primary" to={`${basePath}/create`}>
              <span>
                <PlusOutlined />
                <Localize>USER.Button_Text_Create</Localize>
              </span>
            </Link>
          </div>
        </div>
      </Tables.Main>
    </PageWrapDefault>
  );
};

export default UsersPage;
