import { gql } from '@apollo/client';



export const GET_FOLLOWERS = gql`
    query GetFollowersBy(
      $id: ID!
      $model: modelSubscription!
      $first: Int
      $page: Int
    ){
      followersBy(
          id: $id
          model: $model
          first: $first
          page: $page
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data {
                id
                name
                initials
                nickname
                email
                status
                locale
                role
                gender
                avatar
                about
                itsMe
                me_following
                is_online
                last_active_date
                last_visit_at
                sendPassword {
                  id
                  user_id
                  send_user_id
                  message
                  created_at
                }

                created_at
                updated_at
              }
        }
    }
`;

