import { SortableHandle } from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import Icons from "components/icons";

const InstructionAttachmentField = ({ attachment, dragHandle = false }) => {
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: "grab",
        color: "#999",
        padding: "15px 20px",
      }}
    />
  ));

  return (
    <div className="d-flex align-items-center">
      {dragHandle && <DragHandle />}

      <Icons.Document
        type={attachment.extension}
        style={{ fontSize: "50px" }}
      />

      <div style={{ width: "100%", overflow: "hidden" }}>
        <div className="download-file-info">
          <a
            href={attachment.path}
            className="download-file-name"
            target="_blank"
            rel="noreferrer"
          >
            <strong className="file-name">{attachment.server_name}</strong>
            <strong>.{attachment.extension}</strong>
          </a>
        </div>
        <span style={{ color: "#888888" }}>{attachment.size}</span>
      </div>
    </div>
  );
};

export default InstructionAttachmentField;
