import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Card, Skeleton } from 'antd';
import { FileImageOutlined, LikeOutlined } from '@ant-design/icons';
import PostConst from '../post-const';
const { Meta } = Card;


const PostTopField = ({ post, loadingPosts }) => {

    const thumbnail = post?.media[ 0 ]?.thumbnail;


    return (

        <Link to={ `${ PostConst.basePath }/${ post.id }` }>
            <Card>
                <Skeleton loading={ loadingPosts } avatar active>
                    <Meta
                        avatar={ <Avatar shape="square" size="large" icon={ thumbnail ? <img src={ thumbnail } alt='avatar' /> : <FileImageOutlined /> } /> }
                        title={
                            <div className="d-flex justify-content-between white-space-wrap">
                                <strong style={ { marginRight: '15px' } }>{ post?.title ?? <strong>&mdash;</strong> }</strong>
                                <div className="min-w-100px text-right">{ post?.like_count ? <span><LikeOutlined /> { post?.like_count } Likes</span> : <span><LikeOutlined /> 0 Likes</span> }</div>
                            </div>
                        }
                        description={ post?.author.nickname }
                    />
                </Skeleton>
            </Card>
        </Link>
    );
};


export default PostTopField;
