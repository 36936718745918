import Instruction from "components/instruction";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const InstructionBasePath = Instruction.Const.basePath;

const InstructionOverviewSubPage = ({ instruction, history }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Instructions" }).props
        .children,
      path: `${InstructionBasePath}`,
    },
    {
      label:
        (instruction && instruction?.menu_title) ||
        Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path: (instruction && `${InstructionBasePath}/${instruction?.id}`) || `${InstructionBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className="col-left">
        <Instruction.Forms.Instruction
          instruction={instruction}
          history={history}
        />
      </div>

      <div className="col-right"></div>
    </div>
  );
};

export default InstructionOverviewSubPage;
