import User from "components/user";
import { Localize } from "components/service";


let hashtagColumns = [
    {
        title: <Localize>TABLES.Column_Title_ID</Localize>,
        dataIndex: 'id',
        columnIndex: 'ID',
        sorter: true,
        align: 'center',
        width: '5%'
    },
    {
        title: <Localize>TABLES.Column_Title_FullName</Localize>,
        dataIndex: 'name',
        columnIndex: 'NAME',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Username</Localize>,
        dataIndex: 'nickname',
        columnIndex: 'NICKNAME',
        sorter: true
    },
    {
        title: <Localize>TABLES.Column_Title_Contacts</Localize>,
        dataIndex: 'contacts',
        columnIndex: 'CONTACTS',
    }
];


const hashtagData = ({ model }) => {

    if (!model)
    {
        return [];
    }

    return model.map(user => {

        return {
            key: user.id,
            id: user.id,
            name: <User.Fields.Info user={ user } />,
            nickname: user.nickname ?? <strong>&mdash;</strong>,
            contacts: <User.Fields.Contact user={ user } />,
        };

    });
};


const HashtagFollowersTableHelper = (columnsHide = []) => {

    hashtagColumns = hashtagColumns.filter(({ dataIndex }) => !columnsHide?.includes(dataIndex))

    return {
        columns: hashtagColumns,
        data: hashtagData,
    }
}



export default HashtagFollowersTableHelper;