import UserFields from "../fields";
import { Localize } from "components/service";
import UserHelpers from "../helpers";

let usersColumns = [
  {
    title: <Localize>TABLES.Column_Title_ID</Localize>,
    dataIndex: "id",
    columnIndex: "ID",
    sorter: true,
    align: "center",
    width: "5%",
  },
  {
    title: <Localize>TABLES.Column_Title_FullName</Localize>,
    dataIndex: "name",
    columnIndex: "NAME",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Username</Localize>,
    dataIndex: "nickname",
    columnIndex: "NICKNAME",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Contacts</Localize>,
    dataIndex: "contacts",
    columnIndex: "CONTACTS",
  },
  {
    title: <Localize>TABLES.Column_Title_Role</Localize>,
    dataIndex: "role",
    columnIndex: "ROLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_InstructionViews</Localize>,
    dataIndex: "instr_attachs_read_all",
    columnIndex: "INSTR_ATTACHS_READ_ALL",
    sorter: true,
    align: "center",
  },
  {
    title: <Localize>TABLES.Column_Title_Status</Localize>,
    dataIndex: "status",
    columnIndex: "STATUS",
    sorter: true,
    align: "center",
  },
  {
    // title: 'action',
    dataIndex: "action",
    align: "center",
    width: "4%",
  },
];

const usersData = ({
  model,
  actionFieldsHide,
  entityRemoveUser,
  variables,
}) => {
  if (!model) {
    return [];
  }

  return model.map((user) => {
    return {
      key: user.id,
      id: user.id,
      name: <UserFields.Info user={user} />,
      nickname: user.nickname ?? <strong>&mdash;</strong>,
      contacts: <UserFields.Contact user={user} />,
      role: user.role,
      instr_attachs_read_all: <UserFields.InstructionReadAll user={user} />,
      status: user.status ? (
        <strong className={`${UserHelpers.Functions.statusColor(user.status)}`}>
          {user.status}
        </strong>
      ) : (
        <strong>&mdash;</strong>
      ),
      action: (
        <UserFields.Action
          user={user}
          variables={variables}
          actionFieldsHide={actionFieldsHide}
          entityRemoveUser={entityRemoveUser}
        />
      ),
    };
  });
};

const UsersTableHelper = (columnsHide = []) => {
  usersColumns = usersColumns.filter(
    ({ dataIndex }) => !columnsHide?.includes(dataIndex)
  );

  return {
    columns: usersColumns,
    data: usersData,
  };
};

export default UsersTableHelper;
