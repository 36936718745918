import { gql } from '@apollo/client';
import { cloudinaryReportFieldsFull } from "../fragment/cloudinary-report-frgm";


export const GET_CLOUDINARY_REPORT = gql`
    query GetCloudinaryReport( 
        $id   : ID 
        $type : CloudinaryReportTypeEnum 
    ){
        cloudinaryReport( 
            id   : $id
            type : $type
        ){
            ...cloudinaryReportFieldsFull
        }
    }
    ${ cloudinaryReportFieldsFull }
`;

export const GET_CLOUDINARY_REPORTS = gql`
    query GetCloudinaryReports(
        $text    : String
        $first   : Int
        $page    : Int
        $orderBy : [QueryCloudinaryReportsOrderByOrderByClause!]
        $where   : QueryCloudinaryReportsWhereWhereConditions
    ){
        cloudinaryReports(
            text    : $text,
            first   : $first,
            page    : $page,
            orderBy : $orderBy,
            where   : $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                ...cloudinaryReportFieldsFull
            }
        }
    }
    ${ cloudinaryReportFieldsFull }
`;
