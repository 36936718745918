import CloudinaryReportConst    from "./cloudinary-report-const";
import CloudinaryReportTables   from "./table";
import CloudinaryReportDrawers  from "./drawer";
import CloudinaryReportForms    from "./forms";
import CloudinaryReportElements from "./element";


const CloudinaryReport = {
    Const    : CloudinaryReportConst,
    Tables   : CloudinaryReportTables,
    Drawers  : CloudinaryReportDrawers,
    Forms    : CloudinaryReportForms,
    Elements : CloudinaryReportElements
}

export default CloudinaryReport;